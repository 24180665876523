import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Modal } from "semantic-ui-react";

import { ProgressBar, StatusIcon, Tag } from "./elements";
import MagicButton from "./magicButton";
import { useModal } from "../../hooks/useModal";
import TagManager from "../../NEW-by-model/tags/windows/TagManager";
import { MaybeLink } from "./sharedListItem";

const Item = styled.div`
  margin-bottom: 1rem;
  display: flex;
  border-radius: 10px;

  border: 2px solid;
  border-color: ${(props) => props.colour};
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;

  /* just in case we're inside a Link */
  color: black;
`;

const StatusBar = styled.div`
  background: ${(props) => props.colour};
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;

  display: flex;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
  padding: 0.5rem;
  width: 100%;
`;

const BodyMain = styled.div``;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StatusBarIcon = styled.div`
  padding-left: 0.25rem;
`;

const Modals = styled.div``;

const SharedListItemCardView = ({
  user,
  item,
  progress,
  showProgress,
  linkTo,
  buttons,
}) => {
  // Re-review this view because now SOOOO much duplication with the parent @@@@
  const [tagManagerOpen, tagManagerContext] = useModal();
  const [tag, setTag] = useState();

  const manageTag = (tag) => {
    setTag(tag);
    tagManagerContext.open();
  };

  return (
    <Item colour={item.statusColour()}>
      <StatusBar colour={item.statusColour()}>
        <StatusBarIcon>
          <StatusIcon status={item.status} />
        </StatusBarIcon>
      </StatusBar>
      <Body>
        <BodyMain>
          <MaybeLink linkTo={linkTo}>
            <Title>{item.title}</Title>
          </MaybeLink>
          {showProgress && <ProgressBar progress={progress}></ProgressBar>}
          {item.tags?.map((tag) => (
            <Tag
              key={tag.id}
              onClick={() => manageTag(tag)}
              colour={tag.colour}
            >
              {tag.title}
            </Tag>
          ))}
        </BodyMain>
        <Buttons>
          {buttons ? buttons : <MagicButton user={user} item={item} />}
        </Buttons>
      </Body>

      <Modals>
        <Modal open={tagManagerOpen} onClose={tagManagerContext.onCancel}>
          <TagManager
            tag={tag}
            user={user}
            onComplete={tagManagerContext.closeModal}
          />
        </Modal>
      </Modals>
    </Item>
  );
};

export default observer(SharedListItemCardView);
