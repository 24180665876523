import { destroy, getParent, getSnapshot, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";

import { firebaseUtils } from "../services/firebase.service";
import { calculateStatus, statusToColour } from "../services/utils";
import {
  addCompletedDate,
  clearCompletedDate,
  statusComplete,
  statusInProgress,
  statusNotStarted,
} from "./common";
import { PlanItem } from "./plans";
import { TagItem } from "../NEW-by-model/tags/models/tags";
import { ProjectItem } from "../NEW-by-model/projects/models/projects";

export const GoalItem = types
  .model({
    id: types.identifier,
    index: 0,
    title: types.string,
    description: "",
    status: statusNotStarted,
    projects: types.array(types.reference(types.late(() => ProjectItem))),
    plans: types.array(types.reference(types.late(() => PlanItem))),
    tags: types.array(types.reference(types.late(() => TagItem))),
    completedAt: "",
  })
  /////////////////////////////
  // getters
  /////////////////////////////
  .views(() => ({
    get type() {
      return "goal";
    },
  }))
  /////////////////////////////
  // basic actions
  /////////////////////////////
  .actions((self) => {
    // currently there's no interface
    // to mark something complete as in progress
    // so don't need to worry about removing completedAt dates
    const markInProgress = () => {
      self.status = statusInProgress;
      self.save();
    };

    const markAsComplete = () => {
      self.status = statusComplete;
      addCompletedDate(self);
      self.save();
    };

    const setIndex = (index) => {
      self.index = index;
      self.save();
    };

    return { markInProgress, markAsComplete, setIndex };
  })
  .views((self) => {
    const statusPretty = (showNotStarted) => {
      switch (self.status) {
        case "not-started":
          return showNotStarted ? "not started" : "";

        default:
          return `${self.status}`;
      }
    };

    const statusColour = () => statusToColour(self.status);

    const showProgress = () => self.status === statusInProgress;

    const getProgress = () =>
      self.projects.length
        ? self.projects
            .map((pr) => pr.getProgress())
            .reduce((acc, value) => acc + value) / self.projects.length
        : 0;

    return {
      statusPretty,
      statusColour,
      showProgress,
      getProgress,
    };
  })
  /////////////////////////////
  // lifecycle actions
  /////////////////////////////
  .actions((self) => {
    const beforeDestroy = () => {
      const userId = getParent(self, 3).id;
      const path = ["users", userId, "goals", self.id];

      firebaseUtils.deleteData(path);
    };

    const save = () => {
      const userId = getParent(self, 3).id;
      const snapshot = getSnapshot(self);

      // don't record the id field in the record
      const payload = { ...snapshot };
      delete payload.id;

      const path = ["users", userId, "goals", self.id];

      firebaseUtils.save(path, payload);
    };

    return { beforeDestroy, save };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (plan) => {
      self.plans.push(plan);
      self.save();
    };

    const removePlan = (plan) => {
      const index = self.plans.findIndex((pl) => pl.id === plan);

      if (index < 0) {
        console.log("removePlan, failed to find plan", self.plans, plan);
        return;
      }

      self.plans.splice(index, 1);
      self.save();
    };

    // DRY
    const removeTag = (tag) => {
      const index = self.tags.findIndex((t) => t.id === tag.id);

      if (index > -1) {
        self.tags.splice(index, 1);
        self.save();
      }
    };

    // always backlink unless we're coming from the project model
    // in which case, that model will take care of the project side
    const linkToProject = (projectId, backlink = true) => {
      self.projects.push(projectId);
      self.status = calculateStatus(self, "projects");
      self.save();

      if (backlink) {
        const projectList = getParent(self, 3).projectList;
        const project = projectList.getProject(projectId);

        project.linkToGoal(self);
      }
    };

    // always backlink unless we're coming from the project model
    // in which case, that model will take care of the project side
    const unlinkFromProject = (projectId, backlink = true) => {
      const index = self.projects.findIndex((t) => t.id === projectId);

      if (index > -1) {
        self.projects.splice(index, 1);
        self.status = calculateStatus(self, "projects");
        self.save();

        if (backlink) {
          const projectList = getParent(self, 3).projectList;
          const project = projectList.getProject(projectId);

          project.unlinkFromGoal(self);
        }
      } else {
        console.log("Project not found in unlinkGoalFromProject()", projectId);
      }
    };

    return { addPlan, removePlan, removeTag, linkToProject, unlinkFromProject };
  });
/////////////////////////////
// Order/reorder goals
/////////////////////////////
// .actions((self) => {
// const makeSubgoalNext = () => {
//   const parent = self.parent;

//   let swapWithComparisonFn;

//   if (self.status === statusComplete) {
//     // if status is complete we're just going to move straight to the top
//     swapWithComparisonFn = () => true;
//   } else if (self.status === statusInProgress) {
//     // find the first entry that isn't complete
//     swapWithComparisonFn = (sg) => sg.status !== statusComplete;
//   } else {
//     swapWithComparisonFn = (sg) => sg.status === statusNotStarted;
//   }

//   const selfIndex = parent.subgoals.findIndex((sg) => sg.id === self.id);
//   const swapWithIndex = parent.subgoals.findIndex(swapWithComparisonFn);

//   if (selfIndex === swapWithIndex) {
//     return;
//   }

//   parent.reorderSubgoals(selfIndex, swapWithIndex);
// };

// const reorderSubgoals = (from, to) => {
//   const movedSubgoal = self.subgoals[from];

//   self.subgoals.splice(from, 1);
//   self.subgoals.splice(to, 0, movedSubgoal);
//   self.save();
// };

// return { makeSubgoalNext, reorderSubgoals };
// });

/////////////////////////////
//
// Goal list
//
/////////////////////////////
export const GoalList = types
  .model({
    goals: types.map(GoalItem),
  })
  .views((self) => {
    const getGoal = (modelId) => self.goals.get(modelId);

    const getGoals = () => Array.from(self.goals.values());

    const getBlankModel = () => ({
      title: "",
      description: "",
      tags: [],
    });

    const hasCreatedAnyGoals = () => self.goals.size > 0;

    return { getGoal, getGoals, getBlankModel, hasCreatedAnyGoals };
  })
  /////////////////////////////
  // basic CRUD
  /////////////////////////////
  .actions((self) => {
    const create = (formData) => {
      console.log("Creating goal:", formData);

      formData.id = uuidv4();

      formData.index = self.getGoals().length;

      // save to MST
      self.goals.put(formData);

      // save to DB
      const model = self.goals.get(formData.id);
      model.save();

      return formData.id;
    };

    const update = (formData) => {
      const priorVersion = self.goals.get(formData.id);

      if (priorVersion.status !== formData.status) {
        if (formData.status === statusComplete) {
          addCompletedDate(formData);
        } else if (formData.completedAt) {
          clearCompletedDate(formData);
        }
      }

      // save to MST
      self.goals.put(formData);

      // save to DB
      const model = self.goals.get(formData.id);
      model.save();
    };

    const remove = (goal) => {
      // unlink plans
      goal.plans.forEach((plan) => plan.removeGoal(goal.id));

      // unlink projects
      goal.projects.forEach((project) => project.unlinkFromGoal(goal));

      // do the delete
      destroy(goal);

      // update indices
      const index = goal.index;
      const goals = self.getGoals();

      goals.forEach((model) => {
        if (model.index > index) {
          model.setIndex(model.index - 1);
        }
      });
    };

    const hydrate = (goal) => {
      self.goals.put(goal);
    };

    return { create, update, hydrate, remove };
  })
  .actions((self) => {
    // @@@@ set-up/use
    let unsubFn;

    const init = (userId) => {
      const path = ["users", userId, "goals"];
      const orderBy = false;

      console.log("Initialising goals");

      const hydrateGoal = (goal) => {
        self.hydrate(goal);
      };

      const promise = new Promise((resolve) => {
        const onLoad = () => {
          resolve();
        };

        unsubFn = firebaseUtils.subscribeCollection(
          path,
          hydrateGoal,
          orderBy,
          onLoad
        );
      });

      return promise;
    };

    return { init };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (goalId, planId) => {
      const goal = self.goals.get(goalId);
      goal.addPlan(planId);
    };

    const removePlan = (goalId, planId) => {
      const goal = self.goals.get(goalId);
      goal.removePlan(planId);
    };

    const removeTag = (tag) => {
      const goals = self.getGoals();

      goals.forEach((model) => model.removeTag(tag));
    };

    return { addPlan, removePlan, removeTag };
  })
  /////////////////////////////
  // calculated updates
  /////////////////////////////
  .actions((self) => {
    const updateGoalsOrder = (newList) => {
      newList.forEach((model, index) => model.setIndex(index));
    };

    return { updateGoalsOrder };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const getGoalsWithTag = (tag) => {
      const goals = self.getGoals();

      const matches = goals.filter((model) =>
        model.tags.some((t) => t.id === tag.id)
      );

      return matches;
    };

    return { getGoalsWithTag };
  });
