import React, { useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button } from "semantic-ui-react";

import utils from "../services/utils";
import PlanView from "../components/plans/planView";
import AppStateContext from "../contexts/appState";
import { Overview } from "../components/ui/elements";

const DateNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const Plans = () => {
  const navigate = useNavigate();
  const { userStore } = useContext(AppStateContext);
  const { date } = useParams();

  if (!userStore.userItem) {
    return null;
  }

  const navigateTo = (route) => navigate(route);

  const today = utils.dates.today();
  const activeDate = date || today;

  const dateWeekCommenced = utils.dates.startOfWeek(activeDate);
  const isThisWeek = dateWeekCommenced === utils.dates.startOfWeek(today);
  const isToday = activeDate === today;
  const whichDayIsIt = utils.dates.dayOfWeek(activeDate);
  const lastWeek = utils.dates.previousWeek();
  const numDays = Math.min(
    5,
    utils.dates.daysBetween(today, dateWeekCommenced) + 1
  );
  const days = Array.from({ length: numDays }).map((_, idx) => {
    const date = utils.dates.dateWithOffset(dateWeekCommenced, idx);
    return {
      day: utils.dates.dayOfWeek(date),
      date,
    };
  });

  const titleForWeek = isThisWeek ? "This week's plan" : "Last week's plan";
  const titleForDay = isToday ? "Today's plan" : `${whichDayIsIt}'s plan`;

  const allGoals = userStore.userItem.getGoals();
  const allChores = userStore.userItem.getChores();
  const allProjects = userStore.userItem.getProjects();

  const planForDay = userStore.userItem.getDailyPlan(activeDate);
  const planForWeek = userStore.userItem.getWeeklyPlan(dateWeekCommenced);

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section>Manage Plans</Breadcrumb.Section>
      </Breadcrumb>

      <DateNav>
        <Button
          primary={!isThisWeek}
          onClick={() => navigateTo(`/plans/${lastWeek}`)}
        >
          Last Week
        </Button>
        <Button primary={isThisWeek} onClick={() => navigateTo("/plans")}>
          This Week
        </Button>
      </DateNav>

      <PlanView
        user={userStore.userItem}
        plan={planForWeek}
        title={titleForWeek}
        editable={isThisWeek}
        allGoals={allGoals}
        allChores={allChores}
        allProjects={allProjects}

        // createGoal={userStore.userItem.createGoal}
        // createChore={userStore.userItem.createChore}
      />

      <DateNav>
        {days.map((day) => (
          <Button
            primary={whichDayIsIt === day.day}
            key={day.day}
            onClick={() => navigateTo(`/plans/${day.date}`)}
            content={day.day}
          />
        ))}
      </DateNav>

      <PlanView
        user={userStore.userItem}
        plan={planForDay}
        title={titleForDay}
        editable={isToday}
        allGoals={allGoals}
        allChores={allChores}
        allProjects={allProjects}

        // createGoal={userStore.userItem.createGoal}
        // createChore={userStore.userItem.createChore}
      />
    </Overview>
  );
};

export default observer(Plans);
