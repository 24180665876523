import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";

const TaskEditorNew = ({ addTask, optionsList, Header, Content, Actions }) => {
  const titleId = `title`;

  const [task, setTask] = useState({});

  const onTaskUpdate = (_event, { value }) => {
    const newTask = {};

    // @@@@ there must be a better way to do this
    // @@@@ adding goals doesn't currently work
    // not currently desirable anyway, but should remove any relevant code
    if (value.search("goal:") === 0) {
      const id = value.slice(5);

      newTask.goal = id;
      newTask.project = null;
      newTask.chore = null;
    } else if (value.search("chore:") === 0) {
      const id = value.slice(6);

      newTask.chore = id;
      newTask.goal = null;
      newTask.project = null;
    } else if (value.search("project:") === 0) {
      const id = value.slice(8);

      newTask.project = id;
      newTask.goal = null;
      newTask.chore = null;
    } else {
      console.log("Something odd happened in onTaskUpdate()");
    }

    setTask(newTask);
  };

  return (
    <>
      <Header>Add an item</Header>

      <Content>
        <Form>
          <Form.Group>
            <Form.Select
              fluid
              search
              options={optionsList}
              placeholder="Choose a project or chore"
              onChange={onTaskUpdate}
              label={{
                children: "Choose a project or chore",
                htmlFor: titleId,
              }}
              searchInput={{ id: titleId }}
              width={8}
            />
          </Form.Group>
        </Form>
      </Content>

      <Actions>
        <Button
          color="green"
          content="Add to plan"
          icon="add"
          labelPosition="right"
          disabled={!(task.goal || task.chore || task.project)} // @@@@ can improve this?? Hopefully
          onClick={() => addTask(task)}
        />
      </Actions>
    </>
  );
};

export default TaskEditorNew;
