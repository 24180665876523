import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Card, Button, Message, Modal } from "semantic-ui-react";

import PlanEditor from "./planEditor";
import SharedListItem from "../ui/sharedListItem";
import { linkTo } from "../../services/utils";

const Plan = styled.div`
  padding-bottom: 1em;
`;

// @@@@ not ideal
// works for now
const OverrideCard = styled(Card)`
  margin: 0 !important;
`;

const PlanView = observer(
  ({ user, plan, title, editable, allGoals, allChores, allProjects }) => {
    const [editorOpen, setEditorOpen] = useState(false);

    const onCancel = () => {
      setEditorOpen(false);
    };

    return (
      <OverrideCard>
        <Card.Content header={title} />

        {plan.tasks?.length ? (
          <>
            <Card.Content>
              <Plan>
                {plan.tasks.map((task) => (
                  <SharedListItem
                    user={user}
                    item={task.underlyingObject}
                    cardView
                    linkTo={linkTo(task)}
                    key={task.id}
                  />
                ))}
              </Plan>
            </Card.Content>

            {editable && (
              <Card.Content extra>
                <Button primary onClick={() => setEditorOpen(true)}>
                  Edit plan
                </Button>
              </Card.Content>
            )}
          </>
        ) : (
          <>
            {editable ? (
              <>
                <Card.Content>
                  <Message warning>No plan made</Message>
                </Card.Content>
                <Card.Content extra>
                  <Button primary onClick={() => setEditorOpen(true)}>
                    Create a plan
                  </Button>
                </Card.Content>
              </>
            ) : (
              <Card.Content>
                <Message warning>No plan made</Message>
              </Card.Content>
            )}
          </>
        )}

        {/* @@@@ note - allGoals and allChores are being passed pretty deep, can we avoid that? */}
        <Modal open={editorOpen} onClose={onCancel}>
          <PlanEditor
            user={user}
            plan={plan}
            allGoals={allGoals}
            allChores={allChores}
            allProjects={allProjects}
            Header={Modal.Header}
            Content={Modal.Content}
            Actions={Modal.Actions}
          />
        </Modal>
      </OverrideCard>
    );
  }
);

export default PlanView;
