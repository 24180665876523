import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

import { statusOptionsForDropdown as statusOptions } from "../../models/common";

const { Header, Content, Actions } = Modal;

const GoalEditor = ({ model, onSave, onCancel, onDelete, user, tags }) => {
  const [formData, setFormData] = useState({
    ...model,
  });

  // init UI
  const isNew = !model.id;
  const title = isNew ? "Create new goal" : "Edit goal";
  const statusLabel = model.projects?.length
    ? "Status - calculated from projects"
    : "Status";

  // DRY @@@@
  const tagOptions = tags?.map((tag) => ({
    key: tag.id,
    text: tag.title,
    value: tag.id,
  }));

  const onSubmit = (event) => {
    event.preventDefault();

    onSave(formData);
  };

  const cancel = (event) => {
    event.preventDefault();

    onCancel();
  };

  const deleteGoal = (event) => {
    event.preventDefault();

    const confirmed = window.confirm(
      `Would you like to delete ${formData.title}?`
    );

    if (confirmed) {
      onDelete(formData);
    }
  };

  const onTitleUpdate = (_event, { value }) => {
    const updatedData = { ...formData, title: value };

    setFormData(updatedData);
  };

  const onStatusUpdate = (_event, { value }) => {
    const updatedData = { ...formData, status: value };

    setFormData(updatedData);
  };

  const onDescriptionUpdate = (_event, { value }) => {
    const updatedData = { ...formData, description: value };

    setFormData(updatedData);
  };

  const onTagsUpdate = (_event, { value }) => {
    const updatedData = { ...formData, tags: value };

    setFormData(updatedData);
  };

  const onTagCreated = (_event, { value }) => {
    const newTagId = user.createTag({ title: value });
    const updatedData = { ...formData, tags: [...formData.tags, newTagId] };

    setFormData(updatedData);
  };

  return (
    <>
      <Header>{title}</Header>

      <Content>
        <Form onSubmit={onSubmit}>
          <Form.Input
            fluid
            required
            label="Title"
            placeholder="Title"
            id="form-input-title"
            value={formData.title}
            onChange={onTitleUpdate}
          />

          <Form.Select
            fluid
            search
            options={statusOptions}
            label={{ children: statusLabel, htmlFor: "form-select-status" }}
            placeholder="Status"
            searchInput={{ id: "form-select-status" }}
            value={formData.status}
            onChange={onStatusUpdate}
            disabled={!!model.projects?.length}
          />

          <Form.TextArea
            label="Description"
            placeholder="Description"
            value={formData.description}
            onChange={onDescriptionUpdate}
          />

          <Form.Select
            fluid
            multiple
            search
            clearable
            allowAdditions
            options={tagOptions}
            label={{
              children: "Labels (optional)",
              htmlFor: "form-select-tags",
            }}
            placeholder="Add label(s)"
            searchInput={{ id: "form-select-tags" }}
            value={formData.tags}
            onChange={onTagsUpdate}
            onAddItem={onTagCreated}
          />
        </Form>
      </Content>

      <Actions>
        <Button primary onClick={onSubmit}>
          Save
        </Button>
        {!isNew && (
          <Button negative onClick={deleteGoal}>
            Delete
          </Button>
        )}
        <Button className="cancel" onClick={cancel}>
          Cancel
        </Button>
      </Actions>
    </>
  );
};

export default GoalEditor;
