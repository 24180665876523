import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";

import AnonHeader from "../widgets/AnonHeader";
import FirebaseContext from "../contexts/firebase";
import { useModal } from "../hooks/useModal";

const { Header, Content } = Modal;

const Container = styled.div`
  font-family: "Lato", sans-serif;
`;

// @@@@ copy of FirstPage
const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (50px)); // this is different to first page
  width: 100%;

  padding-top: 4rem;
  padding-left: max(calc((100vw - 1000px) / 2), 4rem);
  padding-right: max(calc((100vw - 1000px) / 2), 4rem);

  justify-content: center;

  background: lightblue;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 2rem;
  padding-bottom: 3rem;
`;

const FormContainer = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 3rem;

  display: flex;
`;

const FormHeader = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 2rem;
`;

const FormWrapper = styled.div``;

const FormLeft = styled.div`
  flex: 1.5;

  padding-right: 5rem;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`;

const FormRight = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 2rem;
  background: aliceblue;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Alternative = styled.div``;

const AltHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  text-transform: uppercase;
  font-size: 0.8rem;
`;

const AlternativeNavigation = styled.div`
  padding-top: 2rem;
`;

const FakeLink = styled.div`
  color: #4183c4;
  cursor: pointer;

  display: inline-block;
`;

const Modals = styled.div``;

// vast oversharing with SignUp @@@@
export const LogIn = () => {
  const firebase = useContext(FirebaseContext);
  const { signInWithGoogle, signInEmailUser, requestPasswordReset } = firebase;

  const [formError, setFormError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordResetOpen, passwordResetContext] = useModal();

  const handleForm = async (event) => {
    event.preventDefault();

    const error = await signInEmailUser(email, password);

    // should probably check response.error @@@@
    if (error) {
      console.log("error", error);
      setFormError(error);
    }
  };

  const handlePasswordReset = async () => {
    const response = await requestPasswordReset(email);

    if (response.error) {
      console.log("error", response.error);
      toast.error(response.error);
    } else {
      toast("Password reset email sent!");
      passwordResetContext.closeModal();
    }
  };

  const initPasswordReset = () => {
    passwordResetContext.open();
  };

  const onEmailUpdate = (_event, { value }) => {
    setEmail(value);
  };

  const onPasswordUpdate = (_event, { value }) => {
    setPassword(value);
  };

  return (
    <Container>
      <AnonHeader />

      <Main>
        <Heading>Ready to get some Clarity Mate?</Heading>

        <FormContainer>
          <FormLeft>
            <FormHeader>Log in</FormHeader>

            <Alternative>
              <Button
                primary
                icon
                labelPosition="left"
                onClick={() => signInWithGoogle()}
              >
                <Icon name="google" />
                Use your Google account
              </Button>
            </Alternative>

            <FormWrapper>
              <AltHeader>Or do things the old-fashioned way</AltHeader>

              <Form onSubmit={handleForm} error={!!formError}>
                <Form.Input
                  name="email"
                  placeholder="email"
                  id="form-email"
                  label="Email"
                  type="email"
                  autoComplete="username"
                  required
                  value={email}
                  onChange={onEmailUpdate}
                />

                <Form.Input
                  name="password"
                  placeholder="password"
                  id="form-password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={onPasswordUpdate}
                />

                {formError && <Message error content={formError?.error} />}

                <Button primary type="submit">
                  Log in
                </Button>
              </Form>
            </FormWrapper>

            <AlternativeNavigation>
              Forgot your password?{" "}
              <FakeLink onClick={initPasswordReset}>Reset password</FakeLink>
              <br />
              No account? <Link to="/create-account">Sign up</Link>
            </AlternativeNavigation>
          </FormLeft>

          <FormRight>
            <p>This space aspires towards holding a beautiful logo.</p>
          </FormRight>
        </FormContainer>
      </Main>

      <Modals>
        <Modal open={passwordResetOpen} onClose={passwordResetContext.onCancel}>
          <Header>Reset password</Header>

          <Content>
            <Form onSubmit={handlePasswordReset} error={!!formError}>
              <Form.Input
                name="email"
                placeholder="email"
                id="form-email"
                label="Email"
                type="email"
                autoComplete="username"
                required
                value={email}
                onChange={onEmailUpdate}
              />

              {formError && <Message error content={formError?.error} />}

              <Button primary type="submit">
                Reset password
              </Button>
            </Form>
          </Content>
        </Modal>
      </Modals>
    </Container>
  );
};
