import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Modal, Search } from "semantic-ui-react";
import styled from "styled-components";

import AppStateContext from "../../../contexts/appState";
import {
  Description,
  EditButtonCreator,
  GoalViewInProject,
  Overview,
  PageSubTitle,
} from "../../../components/ui/elements";

import ProjectList from "../windows/ProjectList";
import ActionBar from "../../_common/windows/ActionBar";
import Editor from "../windows/Editor";
import SharedListItem from "../../../components/ui/sharedListItem";
import { StyledMessage } from "../../../components/ui/elements"; // fully roll out @@@@
import { useModal } from "../../../hooks/useModal";

const Modals = styled.div``;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3rem;
`;

// consider breaking this into 2 components @@@@
const ProjectsHome = () => {
  // set up
  const { userStore } = useContext(AppStateContext);

  const location = useLocation();
  const navigate = useNavigate();

  const modelId = location.state?.project;
  const type = modelId ? "deliverable" : "project";

  const [creatorOpen, createContext] = useModal(
    userStore?.userItem?.createProject,
    type
  );
  const [editorOpen, editContext] = useModal(
    userStore?.userItem?.updateProject
  );

  const [isReordering, setIsReordering] = useState(false);
  const [tempProjectList, setTempProjectList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  if (!userStore.userItem) {
    return null;
  }

  const navigateUp = () => {
    const state = { project: model.parent?.id };
    const options = { state, replace: true };

    navigate(location.pathname, options);
  };

  // init models
  const user = userStore.userItem;

  const model = modelId && user.getProject(modelId);
  const rootProjects = user.getRootProjects().sort((a, b) => a.index - b.index);

  const projectList = model ? model.deliverables : rootProjects;

  const allProjects = user.getProjects(); // needed for the search

  const blankModel = user.getBlankProject(modelId || null);

  const tagsList = user.getTags();

  // init UI
  const childType = model ? "deliverables" : "projects";

  const breadcrumbs = [];
  let m = model;

  while (m) {
    breadcrumbs.unshift(m);
    m = m.parent;
  }

  // delete
  const onDelete = () => {
    editContext.closeModal();

    navigateUp();

    user.deleteProject(model);
  };

  // reorder
  const startReordering = () => {
    setTempProjectList([...projectList]);

    setIsReordering(true);
  };

  const saveReordering = () => {
    if (model) {
      // as with everything else downstream
      // this function expects the parent to be just an id
      // remembering that root level projects don't have a parent
      // @@@@
      // replace updateProject with a dedicated re-ordering method
      user.updateProject({
        ...model,
        parent: model.parent?.id,
        goal: model.goal?.id,
        deliverables: tempProjectList,
      });
    } else {
      user.updateRootProjectOrder(tempProjectList);
    }

    setIsReordering(false);
  };

  const cancelReordering = () => {
    setIsReordering(false);
  };

  const reordering = {
    start: startReordering,
    save: saveReordering,
    cancel: cancelReordering,
    isReordering,
    type: childType,
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.index === destination.index) {
      return;
    }

    const deliverables = [...tempProjectList];

    const movedDeliverable = deliverables[source.index];
    deliverables.splice(source.index, 1);
    deliverables.splice(destination.index, 0, movedDeliverable);

    setTempProjectList(deliverables);
  };

  // search
  const onResultSelect = (_event, { result }) => {
    const gotoModel = result;
    const state = { project: gotoModel.id };
    const options = { state };

    setSearchValue("");

    // note to self
    // this may need replace: false option
    // when it's possible that the search is performed on a page with router state (e.g. a subproject with a project ID)
    // see sharedListItem change in this commit
    navigate(location.pathname, options);
  };

  const onSearchChange = (_event, { value }) => {
    setSearchValue(value);

    const searchFor = value.toLowerCase();

    const filteredProjects = allProjects.filter(
      (model) => model.title.toLowerCase().search(searchFor) > -1
    );

    setSearchResults(filteredProjects);
  };

  const EditButton = EditButtonCreator(editContext);

  const ViewButton = (
    <Button
      key="view"
      primary
      // icon="edit"
      // labelPosition="left"
      content="View as list"
      // onClick={editContext.open}
      size="mini"
    />
  );

  const sliButtons = [EditButton, ViewButton];

  return (
    <Overview>
      <TopBar>
        <Breadcrumb size={"big"}>
          <Breadcrumb.Section
            as={Link}
            to={{ pathname: "/projects" }}
            state={{ project: null }}
          >
            Manage Projects
          </Breadcrumb.Section>
          {breadcrumbs.map((br, index) => (
            <span key={br.id}>
              <Breadcrumb.Divider icon="right chevron"></Breadcrumb.Divider>
              {index === breadcrumbs.length - 1 ? (
                <Breadcrumb.Section active>{br.title}</Breadcrumb.Section>
              ) : (
                <Breadcrumb.Section
                  as={Link}
                  to={{ pathname: "/projects" }}
                  state={{ project: br.id }}
                >
                  {br.title}
                </Breadcrumb.Section>
              )}
            </span>
          ))}
        </Breadcrumb>

        {!model && (
          <Search
            placeholder="Search projects"
            onResultSelect={onResultSelect}
            onSearchChange={onSearchChange}
            results={searchResults}
            value={searchValue}
          />
        )}
      </TopBar>

      {model && (
        <SharedListItem user={user} item={model} buttons={sliButtons}>
          <Description>{model.description}</Description>
          {model.goal && (
            <GoalViewInProject>
              &bull; Goal: {model.goal.title}
            </GoalViewInProject>
          )}
        </SharedListItem>
      )}

      <PageSubTitle>{model ? "Deliverables" : ""}</PageSubTitle>

      {isReordering && (
        <StyledMessage>
          Drag and drop your {childType} below to reorder them.
        </StyledMessage>
      )}

      <ProjectList
        user={user}
        projects={projectList}
        projectsCopy={tempProjectList}
        model={model}
        isReordering={isReordering}
        onDragEnd={onDragEnd}
      />

      <ActionBar create={createContext} reordering={reordering} />

      <Modals>
        <Modal open={creatorOpen} onClose={createContext.onCancel}>
          <Editor
            onSave={createContext.onSuccess}
            onCancel={createContext.onCancel}
            model={blankModel}
            tags={tagsList}
            user={user}
          />
        </Modal>

        <Modal open={editorOpen} onClose={editContext.onCancel}>
          <Editor
            onSave={editContext.onSuccess}
            onCancel={editContext.onCancel}
            onDelete={onDelete}
            model={model}
            tags={tagsList}
            user={user}
          />
        </Modal>
      </Modals>
    </Overview>
  );
};

export default observer(ProjectsHome);
