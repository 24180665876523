import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "semantic-ui-react";

import { ProgressBar, StatusIcon, Tag } from "./elements";
import MagicButton from "./magicButton";
import SharedListItemCardView from "./sharedLIstItemCardView";
import { useModal } from "../../hooks/useModal";
import TagManager from "../../NEW-by-model/tags/windows/TagManager";

const FullView = styled.div`
  background: white;
  margin-top: 1rem;
  display: flex;
  border-radius: 10px;
  max-width: 400px;
  /* width: fit-content; */

  margin-left: ${(props) => (props.indent ? "1rem" : "0")};
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;

  /* just in case we're inside a Link */
  color: black;
`;

const Status = styled.div`
  opacity: 0.7;
  font-size: 0.7rem;

  /* just in case we're inside a Link */
  color: black;
`;

const StatusBar = styled.div`
  background: ${(props) => props.colour};
  min-width: 10px;
  /* how does this work? */
  height: 100;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  display: flex;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
  padding: 0.5rem;
  width: 100%;
`;

const StatusBarLabel = styled.div`
  font-size: 1.5rem;
  padding: 0.25rem;
`;

const StatusBarIcon = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.1rem;
`;

const Children = styled.div``;

const ButtonsUnder = styled.div`
  border-top: 1px solid lightgrey;
  padding-top: 1rem;
`;

// @@@@ these need better names
const BodyMain = styled.div``;

const ButtonsAfter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Modals = styled.div``;

const SharedListItem = ({
  user,
  item,
  index,
  children,
  buttons,
  indent,
  cardView,
  linkTo,
  isReordering,
  customStatus,
}) => {
  const [tagManagerOpen, tagManagerContext] = useModal();
  const [tag, setTag] = useState();

  const showProgress = item.showProgress();
  const progress = showProgress && item.getProgress();

  const manageTag = (tag) => {
    setTag(tag);
    tagManagerContext.open();
  };

  if (cardView) {
    return (
      <SharedListItemCardView
        user={user}
        item={item}
        progress={progress}
        showProgress={showProgress}
        linkTo={linkTo}
        buttons={buttons}
      />
    );
  }

  return (
    <FullView indent={indent}>
      <StatusBar colour={item.statusColour()}>
        {index ? (
          <StatusBarLabel>{index}</StatusBarLabel>
        ) : (
          <StatusBarIcon>
            <StatusIcon status={item.status} />
          </StatusBarIcon>
        )}
      </StatusBar>
      <Body>
        <BodyMain>
          <MaybeLink linkTo={linkTo}>
            <Title>{item.title}</Title>
          </MaybeLink>
          {customStatus ? (
            <>{customStatus}</>
          ) : (
            <Status>
              {item.statusPretty(true)}{" "}
              {showProgress && <ProgressBar progress={progress}></ProgressBar>}
            </Status>
          )}
          {children && <Children>{children}</Children>}
          {buttons && <ButtonsUnder>{buttons}</ButtonsUnder>}
          {item.tags.map((tag) => (
            <Tag
              key={tag.id}
              onClick={() => manageTag(tag)}
              colour={tag.colour}
            >
              {tag.title}
            </Tag>
          ))}
        </BodyMain>
        {!isReordering && (
          <ButtonsAfter>
            <MagicButton user={user} item={item} />
          </ButtonsAfter>
        )}
      </Body>

      <Modals>
        <Modal open={tagManagerOpen} onClose={tagManagerContext.onCancel}>
          <TagManager
            tag={tag}
            user={user}
            onComplete={tagManagerContext.closeModal}
          />
        </Modal>
      </Modals>
    </FullView>
  );
};

export default observer(SharedListItem);

export const MaybeLink = ({ linkTo, children }) => {
  if (!linkTo) {
    return <>{children}</>;
  }

  const { pathname, state } = linkTo;

  return (
    <Link to={pathname} state={state} replace={false}>
      {children}
    </Link>
  );
};
