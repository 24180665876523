import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Form, FormField, Icon, Message } from "semantic-ui-react";

import AnonHeader from "../widgets/AnonHeader";
import FirebaseContext from "../contexts/firebase";

const Container = styled.div`
  font-family: "Lato", sans-serif;
`;

// @@@@ copy of FirstPage
const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (50px)); // this is different to first page
  width: 100%;

  padding-top: 4rem;
  padding-left: max(calc((100vw - 1000px) / 2), 4rem);
  padding-right: max(calc((100vw - 1000px) / 2), 4rem);

  justify-content: center;

  background: lightblue;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 2rem;
  padding-bottom: 3rem;
`;

const FormContainer = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 3rem;

  display: flex;
`;

const FormHeader = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 2rem;
`;

const FormWrapper = styled.div``;

const FormLeft = styled.div`
  flex: 1.5;

  padding-right: 5rem;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`;

const FormRight = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 2rem;
  background: aliceblue;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Alternative = styled.div``;

const AltHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  text-transform: uppercase;
  font-size: 0.8rem;
`;

const AlternativeNavigation = styled.div`
  padding-top: 2rem;
`;

export const SignUp = () => {
  const firebase = useContext(FirebaseContext);
  const { signInWithGoogle, registerEmailUser } = firebase;

  const [formError, setFormError] = useState(null);

  const handleForm = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const email = formData.get("email");
    const password = formData.get("password");

    const error = await registerEmailUser(email, password);

    if (error) {
      console.log("error", error);
      setFormError(error);
    }
  };

  return (
    <Container>
      <AnonHeader />

      <Main>
        <Header>Ready to get some Clarity Mate?</Header>

        <FormContainer>
          <FormLeft>
            <FormHeader>Create account</FormHeader>

            <Alternative>
              <Button
                primary
                icon
                labelPosition="left"
                onClick={() => signInWithGoogle()}
              >
                <Icon name="google" />
                Use your Google account
              </Button>
            </Alternative>

            <FormWrapper>
              <AltHeader>Or do things the old-fashioned way</AltHeader>

              <Form onSubmit={handleForm} error={!!formError}>
                <Form.Input
                  name="email"
                  placeholder="email"
                  id="form-email"
                  label="Email"
                  type="email"
                  autoComplete="username"
                  required
                />

                <Form.Input
                  name="password"
                  placeholder="password"
                  id="form-password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  required
                />

                {formError && <Message error content={formError?.error} />}

                <Button primary type="submit">
                  Create account
                </Button>
              </Form>
            </FormWrapper>

            <AlternativeNavigation>
              Have an account? <Link to="/log-in">Log in</Link>
            </AlternativeNavigation>
          </FormLeft>

          <FormRight>
            <p>
              One day we'll have a nice logo to go here. It's not a priority
              yet, but I'm sure it will be nice when it's done.
            </p>
          </FormRight>
        </FormContainer>
      </Main>
    </Container>
  );
};
