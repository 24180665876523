import React from "react";
import styled from "styled-components";
import { Header } from "semantic-ui-react";

import { Overview } from "../ui/elements";
import PlanView from "./planView";

const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const Element = styled.div`
  padding-right: 1em;
  /* use a media query to lose the padding once the page is wide enough */
  padding-bottom: 1em;
`;

const PlansOverview = ({ user, plans, allGoals, allChores, allProjects }) => {
  return (
    <Overview>
      <Header as="h2">Your Plans</Header>

      <PlansContainer>
        <Element>
          <PlanView
            user={user}
            plan={plans.planForWeek}
            title="This week's plan"
            editable={true}
            allGoals={allGoals}
            allChores={allChores}
            allProjects={allProjects}
          />
        </Element>

        <Element>
          <PlanView
            user={user}
            plan={plans.planForToday}
            title="Today's plan"
            editable={true}
            allGoals={allGoals}
            allChores={allChores}
            allProjects={allProjects}
          />
        </Element>
      </PlansContainer>
    </Overview>
  );
};

export default PlansOverview;
