import React from "react";
import { Button } from "semantic-ui-react";

import { ActionsMenu } from "../../../components/ui/elements"; // fully roll out @@@@

// rename create to createContext, or similar? @@@@
const ActionBar = ({ create, reordering }) => {
  return (
    <>
      {reordering.isReordering ? (
        <ActionsMenu>
          <Button content="Save" positive onClick={reordering.save} />

          <Button
            content="Cancel (discard changes)"
            onClick={reordering.cancel}
          />
        </ActionsMenu>
      ) : (
        <ActionsMenu>
          <Button
            primary
            icon="add"
            labelPosition="left"
            content={`Add a ${create.type}`}
            onClick={create.open}
          />

          <Button
            primary
            icon="arrows alternate vertical"
            labelPosition="left"
            content={`Re-order ${reordering.type}`}
            onClick={reordering.start}
            disabled={reordering.disabled}
          />
        </ActionsMenu>
      )}
    </>
  );
};

export default ActionBar;
