import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const Post = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 10px;
`;

const Heading = styled.h1`
  padding-bottom: 2rem;
`;

const Para = styled.div`
  max-width: 70ch;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
`;

const List = styled.ul`
  margin-top: 0;
`;

const ListItem = styled.li``;

const Subheading = styled.h3`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

export const ForceMultipliers = () => {
  return (
    <Post>
      <Helmet>
        <title>
          Force multipliers - are you getting the most out of yours?
        </title>
        <meta
          property="og:title"
          content="Force multipliers - are you getting the most out of yours?"
        />
        <meta
          property="og:description"
          content="What the hell is a force multiplier and are you getting the most out of yours?"
        />
      </Helmet>

      <Heading>
        Force multipliers - are you getting the most out of yours?
      </Heading>

      <Para>
        Force multipliers - by definition - give you immense bang for your buck.
        Are you making the most out of yours? It may be a term originating with
        military science but they can really help small business owners get
        better results.
      </Para>

      <Para>Here's what we're going to cover:</Para>

      <List>
        <ListItem>What the hell is a force multiplier</ListItem>

        <ListItem>
          Identifying your two most important force multipliers (or just one if
          you're 'lucky'!)
        </ListItem>

        <ListItem>How to put this into practice to get results</ListItem>
      </List>

      <Subheading>What's a force multiplier anyway?</Subheading>

      <Para>
        Force multiplier is a term originating with military science. It's
        basically a tool or asset that lets us do more with what we've got. In
        the original case, it meant literally doing more with the troops (force)
        available without adding more people.
      </Para>

      <Para>
        But we're - I'm going to say fortunately - not involved in military
        matters. What does it mean to small business owners?
      </Para>

      <Para>
        I'd say it's as simple and as nuanced as 'getting greater rewards for
        the same effort'.
      </Para>

      <Para>
        Can you balance books ten times quicker than the next person? Can you
        create landing pages that convert twice as often? Can you coach people
        so that they achieve things they otherwise wouldn't?
      </Para>

      <Para>That's a force multiplier.</Para>

      <Subheading>
        So what are these two 'most important' force multipliers?
      </Subheading>

      <Para>
        Your business' force multiplier (FM from here on), is the thing you
        sell. What are you so good at, that you can sell it, as a product or
        service? What value are you selling that is so powerful that it's
        cheaper for someone to buy from you than to do it themselves?
      </Para>

      <Para>
        Your personal FM is perhaps less obvious. Within your business, what is
        it that you do really well that makes your business successful? It could
        be the same FM as the first but it's probably different. If you're a
        copy-writer or social media guru, then that could be the thing you do
        that makes your business successful. But if you're a coach, or a
        book-keeper, or an architect then being good at what you're selling
        doesn't necessarily make the business successful. What is your FM here?
        Sales, marketing, building products, operational efficiency… The list
        isn't endless, but it's damn big.
      </Para>

      <Subheading>Putting it into practise</Subheading>

      <Para>
        So far, all really interesting. Sure. But how does knowing your force
        multipliers make you more successful?
      </Para>

      <Para>
        Here's the first thing. To sell more, make it really obvious what it is
        you're selling and what the impact will be for the lucky souls buying
        it. Your customers are essentially buying your force multiplier. Make it
        clear what that is and what it does for your customers. Demonstrate why
        they should care. On your website, on your social channels, everywhere.
      </Para>

      <Para>You're probably already doing this, but worth checking!</Para>

      <Para>
        For your personal FM, think about what you can do to make the most out
        of it. Leverage it, you might say. If you were into that sort of thing.
        Lean into what you're good at. At the same time, look at where you're
        working outside your strengths. Where can you best invest your resources
        into buying other people's FMs?
      </Para>

      <Para>
        If you're not good at balancing your books, get someone to do it for
        you. If you're an architect that's unreasonably good at marketing, then
        lean on that skill. Do more of it. And find other things to very
        deliberately not do.
      </Para>

      <Para></Para>

      <Para>
        I hope that was useful. Writing isn't my force multiplier, so you never
        know! If you have any feedback or comments or ideas, I'd love to hear
        them.
      </Para>

      <Para>I'll leave you with the actionable stuff.</Para>

      <Para>
        Until next time, Stay Clear.
        <br />
        Adam.
      </Para>

      <Subheading>The actionable stuff</Subheading>

      <List>
        <ListItem>
          Do you know what your 1-2 most important force multipliers are?
        </ListItem>
        <ListItem>
          Are you making the most of them or are you leaving good craic on the
          table?
        </ListItem>

        <ListItem>
          What can you do right now to make the most of your hard-won skills?
        </ListItem>
      </List>

      <Para>
        Feeling like you want to discuss this here info-grenade with like-minded
        folks? Or want help staying accountable for action? Get in touch to join
        the community [[link coming soon]].
      </Para>
    </Post>
  );
};
