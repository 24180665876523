import React from "react";
import styled from "styled-components";

import { ForceMultipliers } from "../blog-posts/ForceMultipliers";
import { Overview, PageSubTitle } from "../components/ui/elements";

const Body = styled.div`
  width: fit-content;

  font-family: "Lato", sans-serif;

  h2 {
    padding-bottom: 3rem;
  }
`;

export const UserBlog = () => {
  return (
    <Overview>
      <Body>
        <PageSubTitle>Blog</PageSubTitle>

        <ForceMultipliers />
      </Body>
    </Overview>
  );
};
