import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Menu, Button, Sidebar } from "semantic-ui-react";

const Outer = styled.div``;

const Container = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const NavBar = ({}) => {
  const mediaBoundary = "(min-width: 600px)";

  // https://stackoverflow.com/questions/54491645/media-query-syntax-for-reactjs
  const [wideScreen, setWideScreen] = useState(
    window.matchMedia(mediaBoundary).matches
  );

  useEffect(() => {
    window
      .matchMedia(mediaBoundary)
      .addEventListener("change", (e) => setWideScreen(e.matches));
  }, []);

  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  const toggleMenu = () => setShowMenu(!showMenu);

  const menuItems = [
    {
      name: "home",
      path: "/",
    },
    // {
    //   name: "guidance",
    //   path: "/guidance",
    // },
    {
      name: "goals",
      path: "/goals",
    },
    {
      name: "projects",
      path: "/projects",
    },
    {
      name: "plans",
      path: "/plans",
    },
    {
      name: "chores",
      path: "/chores",
    },
    {
      name: "progress",
      path: "/progress",
    },
    {
      name: "labels",
      path: "/labels",
    },
    {
      name: "settings",
      path: "/settings",
    },
    {
      name: "blog",
      path: "https://www.claritymate.app/blog",
    },
  ];

  return (
    <Outer>
      {wideScreen ? (
        <Container>
          <Menu pointing>
            {menuItems.map((item) => (
              <Menu.Item
                name={item.name}
                as={NavLink}
                to={item.path}
                key={item.name}
              />
            ))}
          </Menu>
        </Container>
      ) : (
        <Container>
          {/* styles here as semantic ui changes the styling when 
          there are more than one menu on a page! */}
          <Menu pointing style={{ marginBottom: 0 }}>
            {menuItems.map(
              (item) =>
                (item.name === "home" ||
                  // item.name === "guidance" ||
                  item.path === location.pathname) && (
                  <Menu.Item
                    name={item.name}
                    as={NavLink}
                    to={item.path}
                    key={item.name}
                  />
                )
            )}

            <Menu.Menu position="right">
              <Button onClick={toggleMenu}>Show Menu</Button>
            </Menu.Menu>
          </Menu>

          <Sidebar
            as={Menu}
            animation="overlay"
            direction="right"
            vertical
            visible={showMenu}
            width="thin"
            onClick={toggleMenu}
          >
            {menuItems.map((item) => (
              <Menu.Item
                name={item.name}
                as={NavLink}
                to={item.path}
                key={item.name}
              />
            ))}
          </Sidebar>
        </Container>
      )}
    </Outer>
  );
};

export default NavBar;
