import React from "react";
import styled from "styled-components";
import { Button, Modal } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";

import Editor from "./Editor";
import { useModal } from "../../../hooks/useModal";

const { Header, Actions } = Modal;

const Modals = styled.div``;

const TagManager = ({ tag, user, onComplete }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [editorOpen, editContext] = useModal(onSave);

  const modelId = location.state?.tag;

  // edit
  const triggerEdit = () => {
    editContext.open();
  };

  // save
  const onSave = (formData) => {
    user.updateTag(formData);

    onComplete();
  };

  // delete
  const onDelete = () => {
    editContext.closeModal();

    onComplete();

    user.deleteTag(tag);
  };

  // view
  const onView = () => {
    const isSameTag = modelId === tag.id;

    if (isSameTag) {
      onComplete();
      return;
    }

    const options = { state: { tag: tag.id } };

    onComplete();
    navigate("/labels", options);
  };

  return (
    <>
      <Header>Manage Label - "{tag.title}"</Header>

      <Actions>
        <Button
          key="edit"
          primary
          icon="edit"
          labelPosition="left"
          content="Edit label"
          onClick={triggerEdit}
        />

        <Button
          primary
          icon="eye"
          labelPosition="left"
          content="See all related items"
          onClick={onView}
        />
      </Actions>

      <Modals>
        <Modal open={editorOpen} onClose={editContext.onCancel}>
          <Editor
            onSave={onSave}
            onCancel={editContext.onCancel}
            onDelete={onDelete}
            model={tag}
          />
        </Modal>
      </Modals>
    </>
  );
};

export default TagManager;
