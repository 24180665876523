import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Dropdown,
  Message,
  Modal,
  Search,
} from "semantic-ui-react";

import {
  ActionsMenu,
  Description,
  EditButtonCreator,
  Overview,
} from "../components/ui/elements";
import AppStateContext from "../contexts/appState";
import ChoresEditor from "../components/chores/choresEditor";
import { DragDropZone, Droppy, Draggy } from "../components/ui/dragDrop";
import SharedListItem from "../components/ui/sharedListItem";
import { useModal } from "../hooks/useModal";
import { linkTo } from "../services/utils";

const Modals = styled.div``;

const SubList = styled.div`
  padding-bottom: 1rem;
`;

const SubListTitle = styled.h3``;

const MenuBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  margin: 2rem 1rem;
  align-items: center;
`;

const Chores = () => {
  const { userStore } = useContext(AppStateContext);
  const location = useLocation();

  if (!userStore.userItem) {
    return null;
  }

  const modelId = location.state?.chore;
  const tagsList = userStore.userItem.getTags();

  if (modelId)
    return (
      <SingleChoreView
        user={userStore.userItem}
        modelId={modelId}
        tags={tagsList}
      />
    );

  return <ChoreListView user={userStore.userItem} tags={tagsList} />;
};

export default observer(Chores);

const SingleChoreView = ({ user, modelId, tags }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [editorOpen, editContext] = useModal(user.updateChore);

  const navigateUp = () => {
    const state = { chore: null };
    const options = { state, replace: true };

    navigate(location.pathname, options);
  };

  // set up
  const model = user.getChore(modelId);

  // delete
  const onDelete = () => {
    editContext.closeModal();

    navigateUp();

    user.deleteChore(model);
  };

  const EditButton = EditButtonCreator(editContext);

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section
          as={Link}
          to={{ pathname: "/chores" }}
          state={{ chore: null }}
        >
          Manage Chores
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron"></Breadcrumb.Divider>
        <Breadcrumb.Section active>{model.title}</Breadcrumb.Section>
      </Breadcrumb>

      <SharedListItem user={user} item={model} buttons={[EditButton]}>
        <Description>{model.description}</Description>
      </SharedListItem>

      <Modals>
        <Modal open={editorOpen} onClose={editContext.onCancel}>
          <ChoresEditor
            title="Edit chore"
            model={model}
            onSave={editContext.onSuccess}
            onCancel={editContext.onCancel}
            onDelete={onDelete}
            tags={tags}
            user={user}
          />
        </Modal>
      </Modals>
    </Overview>
  );
};

const ChoreListView = observer(({ user, tags }) => {
  // @@@@ use useModal
  const [creatorOpen, setCreatorOpen] = useState(false);
  const [isReordering, setIsReordering] = useState(false);

  const MORE_INCREMENT = 5;
  const [numToShow, setNumToShow] = useState(MORE_INCREMENT);
  const [showAll, setShowAll] = useState(false);

  const [showCompleted, setShowCompleted] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const allChores = user.getChores();
  let filteredChores = user.getFilteredChores(showCompleted);

  const numChores = filteredChores.length;

  if (!showAll) {
    filteredChores = filteredChores.slice(0, numToShow);
  }

  const blankModel = user.getNewChore();

  const showMore = () => setNumToShow(numToShow + MORE_INCREMENT);
  const showLess = () => setNumToShow(numToShow - MORE_INCREMENT);

  const toggleShowAll = () => setShowAll((showing) => !showing);
  const toggleShowCompleted = () => setShowCompleted((showing) => !showing);

  const onOpenCreator = () => {
    setCreatorOpen(true);
  };

  const onCreate = (chore) => {
    user.createChore(chore);

    setCreatorOpen(false);
  };

  const onCancelCreate = () => {
    setCreatorOpen(false);
  };

  const startReordering = () => setIsReordering(true);

  const saveReordering = () => {
    setIsReordering(false);
  };

  const onDragEnd = (result) => {
    const { draggableId: movedChore, source, destination } = result;

    // sometimes, weirdly, destination is undefined
    if (!destination) {
      return;
    }

    const { droppableId: sourceValue, index: sourceIndex } = source;
    const { droppableId: destValue, index: destIndex } = destination;

    user.moveChore(movedChore, sourceValue, destValue, sourceIndex, destIndex);
  };

  const nowShowing = showAll
    ? "Showing all"
    : `Showing ${Math.min(numToShow, numChores)} of ${numChores}`;

  const nowFiltering = showCompleted ? "Completed" : "Not completed";

  // search -- DRY @@@@
  const onResultSelect = (_event, { result }) => {
    const gotoModel = result;
    const state = { chore: gotoModel.id };
    const options = { state, replace: true };

    setSearchValue("");

    navigate(location.pathname, options);
  };

  const onSearchChange = (_event, { value }) => {
    setSearchValue(value);

    const searchFor = value.toLowerCase();

    const filteredModels = allChores.filter(
      (model) => model.title.toLowerCase().search(searchFor) > -1
    );

    setSearchResults(filteredModels);
  };

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section>Manage Chores</Breadcrumb.Section>
      </Breadcrumb>

      <MenuBar>
        <Dropdown button text={nowShowing}>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={showMore}
              disabled={showAll || numToShow >= numChores}
            >
              Show more
            </Dropdown.Item>
            <Dropdown.Item
              onClick={showLess}
              disabled={showAll || numToShow <= MORE_INCREMENT}
            >
              Show less
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleShowAll}>
              Toggle show all
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Checkbox
          toggle
          label={nowFiltering}
          onChange={toggleShowCompleted}
          checked={showCompleted}
        />

        <Search
          placeholder="Search chores"
          onResultSelect={onResultSelect}
          onSearchChange={onSearchChange}
          results={searchResults}
          value={searchValue}
        />
      </MenuBar>

      {isReordering && (
        <Message>
          Drag and drop your chores below to reorder them. Changes take effect
          immediately.
        </Message>
      )}

      <ChoresList
        user={user}
        chores={filteredChores}
        isReordering={isReordering}
        onDragEnd={onDragEnd}
      />

      {isReordering ? (
        <ActionsMenu>
          <Button content="Done" positive onClick={saveReordering} />
        </ActionsMenu>
      ) : (
        <ActionsMenu>
          <Button
            primary
            icon="add"
            labelPosition="left"
            content={`Add a chore`}
            onClick={onOpenCreator}
          />

          {!showCompleted && (
            <Button
              primary
              icon="arrows alternate vertical"
              labelPosition="left"
              content="Re-order chores"
              onClick={startReordering}
              disabled={filteredChores.length < 2}
            />
          )}
        </ActionsMenu>
      )}

      <Modals>
        <Modal open={creatorOpen} onClose={onCancelCreate}>
          <ChoresEditor
            title="Create new chore"
            model={blankModel}
            onSave={onCreate}
            onCancel={onCancelCreate}
            tags={tags}
            user={user}
          />
        </Modal>
      </Modals>
    </Overview>
  );
});

const ChoresList = observer(({ user, chores, isReordering, onDragEnd }) => {
  const highValueChores = chores.filter((chore) => chore.value === "high");
  const mediumValueChores = chores.filter((chore) => chore.value === "medium");
  const lowValueChores = chores.filter((chore) => chore.value === "low");

  if (!chores.length) {
    return <Message warning>No chores yet</Message>;
  }

  return (
    <DragDropZone onDragEnd={onDragEnd}>
      <SubChoresList
        user={user}
        list={highValueChores}
        title="High Value"
        value="high"
        isReordering={isReordering}
      />
      <SubChoresList
        user={user}
        list={mediumValueChores}
        title="Medium Value"
        value="medium"
        isReordering={isReordering}
      />
      <SubChoresList
        user={user}
        list={lowValueChores}
        title="Low Value"
        value="low"
        isReordering={isReordering}
      />
    </DragDropZone>
  );
});

// this needs to be an observer
// to observe items in the list
const SubChoresList = observer(({ user, list, title, value, isReordering }) => {
  if (!list.length && !isReordering) {
    return null;
  }

  if (!isReordering) {
    return (
      <SubList>
        <SubListTitle>{title}</SubListTitle>
        {list.map((model) => (
          <SharedListItem
            key={model.id}
            user={user}
            item={model}
            linkTo={linkTo(model)}
          />
        ))}
      </SubList>
    );
  }

  return (
    <SubList>
      <Droppy id={value}>
        <SubListTitle>{title}</SubListTitle>
        {list.map((model, index) => (
          <Draggy id={model.id} index={index} key={model.id}>
            <SharedListItem user={user} item={model} isReordering />
          </Draggy>
        ))}
      </Droppy>
    </SubList>
  );
});
