import moment from "moment";

import {
  statusComplete,
  statusInProgress,
  statusNotStarted,
} from "../models/common";

export const dateFormat = "YYYY-MM-DD";

// @@@@ at some point will need to deal with the day changing
const utils = {
  dates: {
    today: () => moment().format(dateFormat),
    startOfWeek: (date) => moment(date).startOf("isoWeek").format(dateFormat),
    previousWeek: (date) =>
      moment(date).subtract(7, "days").startOf("isoWeek").format(dateFormat),
    daysBetween: (date1, date2) => moment(date1).diff(moment(date2), "days"),
    dateWithOffset: (date, offsetDays) =>
      moment(date).add(offsetDays, "days").format(dateFormat),
    dayOfWeek: (date) => moment(date).format("dddd"),
  },
};

export const statusToColour = (status) => {
  switch (status) {
    case "not-started":
      return "lightgrey";

    case "ongoing":
      return "orange";

    default:
      return `green`;
  }
};

// @@@@ use these above
export const statusColourNotStarted = "lightgrey";
export const statusColourComplete = "green";

export default utils;

export const calculateStatus = (model, childrenName) => {
  // if the last child was just removed then
  // the only sensible thing to do is return the model's
  // own existing status
  if (model[childrenName].length === 0) {
    return model.status;
  }

  const statuses = model[childrenName].map((sg) => sg.status);

  // if everything is done, then we're done
  if (statuses.every((status) => status === statusComplete)) {
    return statusComplete;
  }

  // if anything has started, then we're started
  if (statuses.some((status) => status !== statusNotStarted)) {
    return statusInProgress;
  }

  // otherwise we've not started
  return statusNotStarted;
};

export const finishedChildren = (model, childName) => {
  if (model[childName].length === 0) {
    return model.status === statusComplete ? 1 : 0;
  }

  return model[childName]
    .map((child) => finishedChildren(child, childName))
    .reduce((acc, value) => acc + value);
};

export const totalChildren = (model, childName) => {
  if (model[childName].length === 0) {
    return 1;
  }

  return model[childName]
    .map((child) => totalChildren(child, childName))
    .reduce((acc, value) => acc + value);
};

export const getModelDepth = (model) => {
  if (!model.parent) {
    return 0;
  }

  return 1 + getModelDepth(model.parent);
};

const getTrueType = (model) => model.subtype || model.type;

export const linkTo = (model) => {
  const type = getTrueType(model);
  const pathname = `/${type}s`; // this is fine for now, might need looking at later
  const state = { [type]: model.id };
  const link = { pathname, state };

  return link;
};
