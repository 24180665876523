import React, { useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import utils from "../services/utils";
import AppStateContext from "../contexts/appState";
import PlansOverview from "../components/plans/plansOverview";
import GoalsOverview from "../components/goals/goalsOverview";
import ChoresOverview from "../components/chores/choresOverview";

const Container = styled.div`
  display: flex;
`;

const Main = styled.div`
  width: 100%;
`;

const UserWelcome = observer(() => {
  const { userStore } = useContext(AppStateContext);

  if (!userStore.userItem) {
    return null;
  }

  const today = utils.dates.today();
  const dateWeekCommenced = utils.dates.startOfWeek();

  const planForToday = userStore.userItem.getDailyPlan(today);
  const planForWeek = userStore.userItem.getWeeklyPlan(dateWeekCommenced);

  const plans = { planForWeek, planForToday };

  // const filter = { toplevel: true };
  // const topLevelGoals = userStore.userItem.getFilteredGoals(filter);
  const allGoals = userStore.userItem.getGoals();
  const allProjects = userStore.userItem.getProjects();
  const chores = userStore.userItem.getChores();

  return (
    <Container>
      <Main>
        <GoalsOverview user={userStore.userItem} goals={allGoals} />

        {/* <Divider /> */}

        <PlansOverview
          user={userStore.userItem}
          plans={plans}
          allGoals={allGoals}
          allChores={chores}
          allProjects={allProjects}
          // createGoal={userStore.userItem.createGoal}
          // createChore={userStore.userItem.createChore}
        />

        {/* <Divider /> */}

        <ChoresOverview user={userStore.userItem} chores={chores} />
      </Main>
    </Container>
  );
});

export default UserWelcome;

// make actions more 'actiony'
// move logout somewhere else
// focus on driving the process
// hold hands, but don't stand on toes
