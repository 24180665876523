import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Card, Button, Message, Header } from "semantic-ui-react";

import { Overview } from "../ui/elements";
import SharedListItem from "../ui/sharedListItem";

const GoalsOverview = ({ user, goals }) => {
  const navigate = useNavigate();

  const manageGoals = () => navigate("/goals");

  const numGoals = goals && Object.keys(goals).length;

  return (
    <Overview>
      <Header as="h2">Your Goals</Header>

      <Card>
        <Card.Content header="Top level goals" />

        <Card.Content>
          {numGoals ? (
            goals.map((goal) => (
              <SharedListItem
                user={user}
                key={goal.id}
                item={goal}
                cardView
                linkTo={{ pathname: "/goals", state: { goal: goal.id } }}
              />
            ))
          ) : (
            <Message warning>No goals yet</Message>
          )}
        </Card.Content>

        <Card.Content extra>
          <Button primary onClick={manageGoals}>
            Manage goals
          </Button>
        </Card.Content>
      </Card>
    </Overview>
  );
};

export default observer(GoalsOverview);
