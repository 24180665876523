import { useContext, useEffect } from "react";

import FirebaseContext from "../contexts/firebase";

// @@@@ is this needed any more?
// can MST take care of this now?
const Auth = () => {
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    firebase.watchForAuthStateChange();
  }, [firebase]);

  return null;
};

export default Auth;
