import React from "react";
import styled from "styled-components";
import { Breadcrumb, Button, Header, Icon, Message } from "semantic-ui-react";
import {
  statusColourComplete,
  statusColourNotStarted,
} from "../../services/utils";

export const PageTitle = ({ children }) => <Header as="h2">{children}</Header>;

export const PageSubTitle = ({ children }) => (
  <Header as="h3">{children}</Header>
);

export const Overview = styled.div`
  padding: 1em;
`;

export const StatusIcon = ({ status }) => {
  switch (status) {
    case "not-started":
      return <Icon name="square" />;

    case "ongoing":
      return <Icon name="play" />;

    case "done":
      return <Icon name="check circle" />;

    default:
      return null;
  }
};

export const ActionsMenu = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ProgressBarOuter = styled.div`
  height: 4px;
  width: 200px;
  background: ${statusColourNotStarted};
  margin-top: 4px;
  margin-bottom: 6px;
`;

const ProgressBarInner = styled.div`
  width: ${(props) => `${props.progress * 100}%`};
  height: 100%;
  background: ${statusColourComplete};
`;

export const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarOuter>
      <ProgressBarInner progress={progress} />
    </ProgressBarOuter>
  );
};

export const StyledMessage = styled(Message)`
  max-width: 400px;
`;

export const ChecklistStatusIcon = ({ complete }) =>
  complete ? <Icon name="check" color="green" /> : null;

export const SpaceMaker = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const Frame = ({ title, children }) => {
  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section>{title}</Breadcrumb.Section>
      </Breadcrumb>

      {children}
    </Overview>
  );
};

export const Description = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 1rem;

  white-space: pre-line;
`;

export const EditButtonCreator = (editContext) => (
  <Button
    key="edit"
    primary
    icon="edit"
    labelPosition="left"
    content="Edit"
    onClick={editContext.open}
    size="mini"
  />
);

export const Tag = ({ colour, onClick, children }) => (
  <TagOuter onClick={onClick}>
    <TagColour colour={colour} />
    <div>{children}</div>
  </TagOuter>
);

const TagColour = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.4rem;

  background: ${(props) => props.colour};
`;

const TagOuter = styled.div`
  color: black;
  border: 1px solid grey;
  border-radius: 10px;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  padding: 2px 7px;
  font-size: 0.7rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
  text-transform: uppercase;

  cursor: pointer;
`;

export const GoalViewInProject = styled.div`
  padding-top: 1rem;
`;

// experimental - not currently used @@@@
export const Hero = styled.div`
  height: 150px;
  background: darkblue;
  color: white;
  width: calc(100% + 28px);
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-top: -14px;
  margin-left: -14px;
`;
