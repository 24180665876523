import React from "react";
import styled from "styled-components";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const DragDropOuter = styled.div`
  /* background-color: ${(props) =>
    props.dragging ? "lightgreen" : "inherit"}; */
`;

export const DragDropZone = ({
  children,
  onDragStart,
  onDragEnd,
  dragging,
}) => (
  <DragDropOuter dragging={dragging}>
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  </DragDropOuter>
);

const DroppyList = styled.div``;

export const Droppy = ({ id, children }) => (
  <Droppable droppableId={id}>
    {(droppableProvided) => (
      <DroppyList
        ref={droppableProvided.innerRef}
        {...droppableProvided.droppableProps}
      >
        {children}
        {droppableProvided.placeholder}
      </DroppyList>
    )}
  </Droppable>
);

const DraggableInner = styled.div`
  /* background-color: ${(props) => (props.isDragging ? "blue" : "white")}; */
`;

export const Draggy = ({ id, index, preventDrag, children }) => {
  if (preventDrag) {
    return <>{children}</>;
  }

  return (
    <Draggable draggableId={id} index={index} key={id}>
      {(draggableProvided, snapshot) => (
        <DraggableInner
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          isDragging={snapshot.isDragging}
          ref={draggableProvided.innerRef}
        >
          {children}
        </DraggableInner>
      )}
    </Draggable>
  );
};
