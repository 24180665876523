import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

import "./styles.css";
import Auth from "./widgets/Auth";
import NavBar from "./widgets/NavBar";
// import NavBarWelcome from "./widgets/NavBarWelcome";
import Loading from "./widgets/Loading";
import Plans from "./pages/Plans";
import Goals from "./pages/Goals";
import Chores from "./pages/Chores";
import UserHome from "./pages/UserHome";
// import Guidance from "./pages/Guidance";
// import Welcome from "./pages/Welcome";
import AppStateContext from "./contexts/appState";
import Progress from "./pages/Progress";
import Settings from "./pages/Settings";

import Projects from "./NEW-by-model/projects/pages/Projects";
import Tags from "./NEW-by-model/tags/pages/Tags";
import { SignUp } from "./pages/SignUp";
import { LogIn } from "./pages/LogIn";
import { UserBlog } from "./pages/UserBlog";

const Container = styled.div`
  max-width: 1024px;
  margin: auto;
  background-image: linear-gradient(90deg, #55a8e7, white);
  min-height: calc(100vh - 50px);
`;

// interesting colour
// rgb(34, 91, 161);

const AppOuter = styled.div`
  min-height: 100vh;
`;

const App = observer(() => {
  // @@@@ maybe change to use context
  const { userStore } = useContext(AppStateContext);

  if (!userStore.authUser) {
    // auth is needed as it contains the
    // listener for login
    return (
      <AppOuter>
        <Auth />
        <ToastContainer position="top-center" />

        <Routes>
          <Route exact path="/create-account" element={<SignUp />} />
          <Route exact path="/log-in" element={<LogIn />} />

          <Route path="*" element={<Navigate to="/log-in" replace />} />
        </Routes>
      </AppOuter>
    );
  }

  if (userStore.userItem?._status === "loading") {
    return (
      <AppOuter>
        <Auth />
        <Loading />

        {/* if a user is logging in, send them to the home page */}
        <Routes>
          <Route path="/log-in" element={<Navigate to="/" replace />} />
          <Route path="/create-account" element={<Navigate to="/" replace />} />
        </Routes>
      </AppOuter>
    );
  }

  // @@@@ nuke this for now, for testing purposes
  // if (!userStore.userItem.hasCreatedAnyGoals()) {
  //   return (
  //     <AppOuter>
  //       <Auth />
  //       <NavBarWelcome />
  //       <Container>
  //         <Routes>
  //           <Route exact path="/" element={<Welcome />} />
  //           <Route exact path="/guidance" element={<Guidance />} />
  //         </Routes>
  //       </Container>
  //     </AppOuter>
  //   );
  // }

  return (
    <AppOuter>
      <Auth />
      <NavBar />

      <Container>
        <Routes>
          <Route exact path="/" element={<UserHome />} />

          <Route exact path="/plans" element={<Plans />} />
          <Route exact path="/plans/:date" element={<Plans />} />

          <Route exact path="/goals" element={<Goals />} />

          <Route exact path="/projects" element={<Projects />} />

          <Route exact path="/chores" element={<Chores />} />

          {/* <Route exact path="/guidance" element={<Guidance />} /> */}

          <Route exact path="/progress" element={<Progress />} />

          <Route exact path="/labels" element={<Tags />} />

          <Route exact path="/settings" element={<Settings />} />

          <Route path="/blog" element={<UserBlog />} />
        </Routes>
      </Container>
    </AppOuter>
  );
});

export default App;
