import React from "react";

import {
  GoalViewInProject,
  StyledMessage,
} from "../../../components/ui/elements"; // fully roll out @@@@
import SharedListItem from "../../../components/ui/sharedListItem";
import { DragDropZone, Draggy, Droppy } from "../../../components/ui/dragDrop";

const ProjectList = ({
  user,
  projects,
  projectsCopy,
  model,
  isReordering,
  onDragEnd,
}) => {
  const warningText = model ? "No deliverables yet" : "No projects yet";
  const shouldIndent = !!model;

  if (!projects.length) {
    return <StyledMessage warning>{warningText}</StyledMessage>;
  }

  if (!isReordering) {
    return projects.map((model) => (
      <SharedListItem
        user={user}
        item={model}
        indent={shouldIndent}
        linkTo={{ pathname: "/projects", state: { project: model.id } }}
        key={model.id}
      >
        {model.goal && (
          <GoalViewInProject>&bull; Goal: {model.goal.title}</GoalViewInProject>
        )}
      </SharedListItem>
    ));
  }

  return (
    <DragDropZone onDragEnd={onDragEnd}>
      <Droppy id="dropId">
        {projectsCopy.map((model, index) => (
          <Draggy key={model.id} id={model.id} index={index}>
            <SharedListItem
              user={user}
              item={model}
              index={index + 1}
              indent={shouldIndent}
              isReordering
            />
          </Draggy>
        ))}
      </Droppy>
    </DragDropZone>
  );
};

export default ProjectList;
