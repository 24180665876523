import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Button, Message, Modal } from "semantic-ui-react";
import styled from "styled-components";

import AppStateContext from "../contexts/appState";
import {
  ActionsMenu,
  Description,
  Overview,
  PageSubTitle,
} from "../components/ui/elements";
import SharedListItem from "../components/ui/sharedListItem";
import GoalEditor from "../components/goals/goalEditor";
import { DragDropZone, Droppy, Draggy } from "../components/ui/dragDrop";
import ActionBar from "../NEW-by-model/_common/windows/ActionBar";
import { useModal } from "../hooks/useModal";
import { useState } from "react";
import GoalLinker from "../components/goals/goalLinker";
import ProjectEditor from "../NEW-by-model/projects/windows/Editor";

const Modals = styled.div``;

// @@@@ constant this
const StyledMessage = styled(Message)`
  max-width: 400px;
`;

const GoalObserver = () => {
  const { userStore } = useContext(AppStateContext);

  const location = useLocation();
  const navigate = useNavigate();

  if (!userStore.userItem) {
    return null;
  }

  // init models
  const user = userStore.userItem;

  const modelId = location.state?.goal;
  const model = modelId && user.getGoal(modelId);

  const navigateUp = () => {
    const state = { goal: null };
    const options = { state, replace: true };

    navigate(location.pathname, options);
  };

  if (model) {
    return <SingleGoalView user={user} model={model} navigateUp={navigateUp} />;
  }

  const modelList = user.getGoals().sort((a, b) => a.index - b.index);

  return <GoalListView user={user} modelList={modelList} />;
};

export default observer(GoalObserver);

const SingleGoalView = observer(({ user, model, navigateUp }) => {
  const [editorOpen, edit] = useModal(user.updateGoal);
  const [linkerOpen, link] = useModal();
  const [creatorOpen, createProject] = useModal((m) => createAndLinkProject(m));

  // init models
  const tagsList = user.getTags();
  const linkableProjects = user.getLinkableProjects();
  const blankProject = user.getBlankProject();

  const projectList = model.projects.slice().sort((a, b) => a.index - b.index);

  // delete
  const onDelete = () => {
    edit.closeModal();

    navigateUp();

    user.deleteGoal(model);
  };

  // link/unlink
  const linkToProject = (projectId) => {
    model.linkToProject(projectId);
  };

  const unlinkFromProject = (projectId) => {
    model.unlinkFromProject(projectId);
  };

  const createAndLinkProject = (project) => {
    const projectId = user.createProject(project);
    model.linkToProject(projectId);
  };

  const EditButton = (
    <Button
      key="edit"
      primary
      icon="edit"
      labelPosition="left"
      content="Edit"
      onClick={edit.open}
      size="mini"
    />
  );

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section
          as={Link}
          to={{ pathname: "/goals" }}
          state={{ goal: null }}
        >
          Manage Goals
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron"></Breadcrumb.Divider>
        <Breadcrumb.Section active>{model.title}</Breadcrumb.Section>
      </Breadcrumb>

      <SharedListItem user={user} item={model} buttons={[EditButton]}>
        <Description>{model.description}</Description>
      </SharedListItem>

      <PageSubTitle>Projects</PageSubTitle>

      {projectList.length === 0 && (
        <StyledMessage warning>No projects yet</StyledMessage>
      )}

      {projectList.map((projectModel) => (
        <SharedListItem
          user={user}
          item={projectModel}
          indent={true}
          linkTo={{
            pathname: "/projects",
            state: { project: projectModel.id },
          }}
          key={projectModel.id}
        />
      ))}

      <ActionsMenu>
        <Button
          primary
          icon="edit"
          labelPosition="left"
          content={`Add/remove linked projects`}
          onClick={link.open}
        />

        <Button
          primary
          icon="add"
          labelPosition="left"
          content={`Create a project`}
          onClick={createProject.open}
        />
      </ActionsMenu>

      <Modals>
        <Modal open={editorOpen} onClose={edit.onCancel}>
          <GoalEditor
            onSave={edit.onSuccess}
            onCancel={edit.onCancel}
            onDelete={onDelete}
            model={model}
            tags={tagsList}
            user={user}
          />
        </Modal>

        <Modal open={linkerOpen} onClose={link.onCancel}>
          <GoalLinker
            linkToProject={linkToProject}
            unlinkFromProject={unlinkFromProject}
            model={model}
            projects={linkableProjects}
            user={user}
          />
        </Modal>

        <Modal open={creatorOpen} onClose={createProject.onCancel}>
          <ProjectEditor
            onSave={createProject.onSuccess}
            onCancel={createProject.onCancel}
            model={blankProject}
            tags={tagsList}
            user={user}
          />
        </Modal>
      </Modals>
    </Overview>
  );
});

const GoalListView = ({ user, modelList }) => {
  const [modalOpen, create] = useModal(user.createGoal, "goal"); // @@@@ does this really need a type passed in???
  const [isReordering, setIsReordering] = useState(false);
  const [tempModelList, setTempModelList] = useState([]);

  // init models
  const blankModel = user.getBlankGoal();

  const tagsList = user.getTags();

  // reorder - @@@@ turn this into a custom hook
  const startReordering = () => {
    setTempModelList([...modelList]);

    setIsReordering(true);
  };

  const saveReordering = () => {
    user.updateGoalsOrder(tempModelList);

    setIsReordering(false);
  };

  const cancelReordering = () => {
    setIsReordering(false);
  };

  const reordering = {
    start: startReordering,
    save: saveReordering,
    cancel: cancelReordering,
    isReordering,
    type: "goals",
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.index === destination.index) {
      return;
    }

    const goals = [...tempModelList];

    const movedGoal = goals[source.index];
    goals.splice(source.index, 1);
    goals.splice(destination.index, 0, movedGoal);

    setTempModelList(goals);
  };

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section
          as={Link}
          to={{ pathname: "/goals" }}
          state={{ goal: null }}
        >
          Manage Goals
        </Breadcrumb.Section>
      </Breadcrumb>

      <GoalList
        user={user}
        modelList={modelList}
        modelListCopy={tempModelList}
        isReordering={reordering.isReordering}
        onDragEnd={onDragEnd}
      />

      <ActionBar create={create} reordering={reordering} />

      <Modals>
        <Modal open={modalOpen} onClose={create.onCancel}>
          {/* could probably just pass create through here @@@@ */}
          <GoalEditor
            onSave={create.onSuccess}
            onCancel={create.onCancel}
            model={blankModel}
            tags={tagsList}
            user={user}
          />
        </Modal>
      </Modals>
    </Overview>
  );
};

const GoalList = ({
  user,
  modelList,
  modelListCopy,
  isReordering,
  onDragEnd,
}) => {
  if (!modelList.length) {
    return <StyledMessage warning>No goals yet</StyledMessage>;
  }

  if (!isReordering) {
    return modelList.map((model) => (
      <SharedListItem
        user={user}
        item={model}
        indent={false}
        linkTo={{ pathname: "/goals", state: { goal: model.id } }} // @@@@ can we improve this??
        key={model.id}
      />
    ));
  }

  return (
    <DragDropZone onDragEnd={onDragEnd}>
      <Droppy id="dropId">
        {modelListCopy.map((model, index) => (
          <Draggy key={model.id} id={model.id} index={index}>
            <SharedListItem
              user={user}
              item={model}
              index={index + 1}
              indent={false}
              isReordering
            />
          </Draggy>
        ))}
      </Droppy>
    </DragDropZone>
  );
};
