import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Breadcrumb, Message } from "semantic-ui-react";
import styled from "styled-components";

import { Overview } from "../../../components/ui/elements";
import SharedListItem from "../../../components/ui/sharedListItem";
import { linkTo } from "../../../services/utils";

const Info = styled.div`
  padding-top: 2rem;
`;

const SingleTag = ({ modelId, user }) => {
  const model = user.getTag(modelId);

  const goals = user.getGoalsWithTag(model);
  const projects = user.getProjectsWithTag(model);
  const chores = user.getChoresWithTag(model);

  const anythingToShow = goals.length || projects.length || chores.length;

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section
          as={Link}
          to={{ pathname: "/labels" }}
          state={{ tag: null }}
        >
          Manage Labels
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron"></Breadcrumb.Divider>
        <Breadcrumb.Section active>{model.title}</Breadcrumb.Section>
      </Breadcrumb>

      <Info>
        {anythingToShow ? (
          <h3>Related items</h3>
        ) : (
          <Message warning>No related items</Message>
        )}
      </Info>

      <Section title="Goals" list={goals} user={user} />
      <Section title="Projects" list={projects} user={user} />
      <Section title="Chores" list={chores} user={user} />
    </Overview>
  );
};

const Block = styled.div`
  padding-top: 2rem;
`;

const Title = styled.h3``;

const Section = ({ title, list, user }) => {
  if (list.length === 0) return null;

  return (
    <Block>
      <Title>{title}</Title>
      {list.map((item) => (
        <SharedListItem
          item={item}
          key={item.id}
          user={user}
          linkTo={linkTo(item)}
        />
      ))}
    </Block>
  );
};

export default observer(SingleTag);
