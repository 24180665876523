import React from "react";
import { Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import { statusInProgress, statusNotStarted } from "../../models/common";

const MagicButton = ({ user, item }) => {
  const realItem = item.type === "task" ? item.underlyingObject : item;

  const { status, type } = realItem;
  const isAlreadyInThisWeeksPlan = user.isItemAlreadyInThisWeeksPlan(item);
  const isAlreadyInTodaysPlan = user.isItemAlreadyInTodaysPlan(item);
  const canBeProgressed =
    type !== "project" || realItem.deliverables.length === 0;

  const addToThisWeeksPlan = () => user.addTaskToThisWeeksPlan(item);
  const addToTodaysPlan = () => user.addTaskToTodaysPlan(item);

  const markInProgress = () => item.markInProgress();
  const markAsComplete = () => item.markAsComplete();

  const options = [];

  const optionAddToWeeksPlan = {
    text: "Add to this week's plan",
    triggerFn: addToThisWeeksPlan,
  };

  const optionAddToTodaysPlan = {
    text: "Add to today's plan",
    triggerFn: addToTodaysPlan,
  };

  const optionMarkInProgress = {
    text: "Mark as in progress",
    triggerFn: markInProgress,
  };

  const optionMarkAsComplete = {
    text: "Mark as complete",
    triggerFn: markAsComplete,
  };

  if (!isAlreadyInThisWeeksPlan) {
    options.push(optionAddToWeeksPlan);
  }

  if (!isAlreadyInTodaysPlan) {
    options.push(optionAddToTodaysPlan);
  }

  if (canBeProgressed) {
    if (status === statusNotStarted) {
      options.push(optionMarkInProgress);
    }

    if (status === statusInProgress) {
      options.push(optionMarkAsComplete);
    }
  }

  if (!options.length) {
    return null;
  }

  return (
    <Dropdown button className="icon" icon="setting" floating>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option.text}
            text={option.text}
            onClick={() => option.triggerFn()}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default observer(MagicButton);
