import { getParent, getSnapshot, types } from "mobx-state-tree";
import moment from "moment";

import { firebaseUtils } from "../services/firebase.service";
import { GoalItem } from "./goals";
import { ChoreItem } from "./chores";
import { ProjectItem } from "../NEW-by-model/projects/models/projects";
import utils, { dateFormat } from "../services/utils";

/////////////////////////////
//
// Task item
//
/////////////////////////////
export const TaskItem = types
  .model({
    goal: types.maybeNull(types.reference(types.late(() => GoalItem))),
    chore: types.maybeNull(types.reference(types.late(() => ChoreItem))),
    project: types.maybeNull(types.reference(types.late(() => ProjectItem))),
  })
  .views((self) => ({
    get subtype() {
      if (self.goal) return "goal";
      if (self.chore) return "chore";
      if (self.project) return "project";

      console.log("Unknown subtype in getSubtype(), self:", { ...self });
      throw new Error(`Unknown subtype in getSubtype(), self: ${{ ...self }}`);
    },
    get underlyingObject() {
      return self[self.subtype];
    },
    get id() {
      return self.underlyingObject.id;
    },
    get type() {
      return "task";
    },
    get title() {
      return self.underlyingObject.title;
    },
    get status() {
      return self.underlyingObject.status;
    },
    get statusPretty() {
      return self.underlyingObject.statusPretty;
    },
    get statusColour() {
      return self.underlyingObject.statusColour;
    },
    get completedAt() {
      return self.underlyingObject.completedAt;
    },
    hasChildren() {
      if (self.goal) return false; // tbc
      if (self.chore) return false;
      if (self.project) return self.underlyingObject.hasChildren();
    },
    getProgressSince(dateFrom) {
      if (self.goal) return false; // tbc
      if (self.chore) return false;
      if (self.project) return self.underlyingObject.getProgressSince(dateFrom);
    },
    showProgress() {
      return self.underlyingObject.showProgress();
    },
    getProgress() {
      return self.underlyingObject.getProgress();
    },
    getRootDetails() {
      return self.underlyingObject.getRootDetails();
    },
  }))
  /////////////////////////////
  // basic setters
  /////////////////////////////
  .actions((self) => {
    const markInProgress = () => {
      const underlying = self.underlyingObject;

      underlying.markInProgress();
    };

    const markAsComplete = () => {
      const underlying = self.underlyingObject;

      underlying.markAsComplete();
    };

    return { markInProgress, markAsComplete };
  });

/////////////////////////////
//
// Plan item
//
/////////////////////////////
export const PlanItem = types
  .model({
    id: types.identifier,
    date: types.string, // for now
    type: types.string, // for now
    tasks: types.array(TaskItem),
    // _status: types.string
  })
  .actions((self) => ({
    save: () => {
      // need to extract the user Id from the user a few levels up
      const userId = getParent(self, 3).id;
      const snapshot = getSnapshot(self);
      const payload = { ...snapshot };
      const { type, date } = payload;
      const path = ["users", userId, type, date];

      console.log("Saving plan", type, date);

      firebaseUtils.save(path, payload);
    },
  }))
  /////////////////////////////
  // unlink
  /////////////////////////////
  .actions((self) => {
    const _removeGoal = (goal) => {
      self.removeGoal(goal.id);

      const goalsList = getParent(self, 3).goalList;
      goalsList.removePlan(goal.id, self.id);
    };

    const _removeProject = (project) => {
      self.removeProject(project.id);

      const projectList = getParent(self, 3).projectList;
      projectList.removePlan(project.id, self.id);
    };

    const _removeChore = (chore) => {
      self.removeChore(chore.id);

      const choresList = getParent(self, 3).choresList;
      choresList.removePlan(chore.id, self.id);
    };

    const removeTask = (task) => {
      // @@@@ is there a better way to do this?
      if (task.goal) {
        _removeGoal(task.goal);
      } else if (task.project) {
        _removeProject(task.project);
      } else if (task.chore) {
        _removeChore(task.chore);
      } else {
        console.log("Something odd happened in removeTask()");
      }
    };

    return { removeTask };
  })
  /////////////////////////////
  // remove tasks
  /////////////////////////////
  .actions((self) => {
    // @@@@ Can we DRY this up??
    const removeChore = (choreId) => {
      const index = self.tasks.findIndex((task) => task.chore?.id === choreId);

      if (index < 0) {
        console.log("removeChore: failed to find", choreId);
        return;
      }

      self.tasks.splice(index, 1);
      self.save();
    };

    const removeGoal = (goalId) => {
      const index = self.tasks.findIndex((task) => task.goal?.id === goalId);

      if (index < 0) {
        console.log("removeGoal: failed to find", goalId);
        return;
      }

      self.tasks.splice(index, 1);
      self.save();
    };

    const removeProject = (projectId) => {
      const index = self.tasks.findIndex(
        (task) => task.project?.id === projectId
      );

      if (index < 0) {
        console.log("removeProject: failed to find", projectId);
        return;
      }

      self.tasks.splice(index, 1);
      self.save();
    };

    return { removeChore, removeGoal, removeProject };
  })
  /////////////////////////////
  // views
  /////////////////////////////
  .views((self) => ({
    get label() {
      // @@@@ this will need to adapt when a week other
      // than this week can be edited
      if (self.type === "weekly") {
        return "this week";
      }

      const today = new Date();

      const todayDate = moment(today).format(dateFormat);
      const yesterdayDate = moment(today).subtract(1, "day").format(dateFormat);

      if (self.date === todayDate) {
        return "today";
      } else if (self.date === yesterdayDate) {
        return "yesterday";
      }

      // @@@@ we shouldn't currently be able to fall through
      // but that could be added later

      console.log("Unexpected date in plans.label()");
      return "";
    },
  }))
  /////////////////////////////
  // Add tasks to plans
  /////////////////////////////
  .actions((self) => {
    // this does the job of maintaining a
    // prioritised list of tasks
    const _insertTask = (task, type, todoModel) => {
      // order is goals, projects, then chores
      // within those we follow the ordering/implicit priority

      // @@@@ it's possible this could all be simplified
      // if every type implement higherPriorityThan
      // & was aware of their pecking order between different types

      if (type === "goal") {
        // goals are going to be overhauled
        // for now, when adding a goal let's just add it to the top
        // of the list
        self.tasks.unshift(task);
        return;
      }

      if (type === "project") {
        const goalsInList = self.tasks.filter((t) => t.goal);
        const numGoalsInList = goalsInList.length;

        const projectsInList = self.tasks.filter((t) => t.project);
        const numProjectsInList = projectsInList.length;

        // if this is the first project in the list
        // insert it immediately after the goals
        // (not technically required, but more obvious perhaps than falling through from an empty forEach)
        if (numProjectsInList === 0) {
          self.tasks.splice(numGoalsInList, 0, task);
          return;
        }

        // now look for a descendant
        // or any other condition that makes this new project
        // higher priority than any other project currently
        // in the list
        let found = false;
        projectsInList.forEach((p, index) => {
          // task.project causes a crash. Why? @@@@
          // not sure, but easy enough to work around
          // if (task.project.isHigherPriorityThan(p)) {
          if (!found && todoModel.isHigherPriorityThan(p)) {
            found = true;

            self.tasks.splice(numGoalsInList + index, 0, task);
          }
        });

        if (!found) {
          // this must be lower priority than all the other projects
          // so stick it at the end
          self.tasks.splice(numGoalsInList + numProjectsInList, 0, task);
        }

        return;
      }

      if (type === "chore") {
        // needs finessing @@@@
        self.tasks.push(task);
        return;
      }

      console.log("Unknown type in _insertTask():", task, type);
    };

    // addTask services both the plan editor and magic buttons
    // magic buttons can be associated with plan items (i.e. tasks)
    // or non-plan items (i.e. goals, projects, chores)
    const addTask = (item) => {
      const realType = item.type === "task" ? item.subtype : item.type;
      const task = { [realType]: item.id };

      const newTask = TaskItem.create(task);

      _insertTask(newTask, realType, item);
      self.save();

      const user = getParent(self, 3);
      const itemList = user.getRelevantList(realType);

      itemList.addPlan(item.id, self.id);
    };

    return { addTask };
  })
  /////////////////////////////
  // Status checks
  /////////////////////////////
  .views((self) => {
    const containsItem = (item) => {
      const found = self.tasks.find((task) => task.id === item.id);

      return !!found;
    };

    return { containsItem };
  });

/////////////////////////////
//
// Plan list
//
/////////////////////////////
export const PlanList = types
  .model({
    plans: types.map(PlanItem),
  })
  .actions((self) => {
    // @@@@ set-up/use
    let unsubFn;

    const hydrate = (plan) => {
      self.plans.put(plan);
    };

    const init = (userId) => {
      const dailyPath = ["users", userId, "daily"];
      const weeklyPath = ["users", userId, "weekly"];

      console.log("Initialising plans");

      const hydratePlan = (plan) => {
        self.hydrate(plan);
      };

      const promiseDaily = new Promise((resolve) => {
        const onLoad = () => {
          resolve();
        };

        unsubFn = firebaseUtils.subscribeCollection(
          dailyPath,
          hydratePlan,
          false,
          onLoad
        );
      });

      const promiseWeekly = new Promise((resolve) => {
        const onLoad = () => {
          resolve();
        };

        unsubFn = firebaseUtils.subscribeCollection(
          weeklyPath,
          hydratePlan,
          false,
          onLoad
        );
      });

      return Promise.all([promiseDaily, promiseWeekly]);
    };

    return { init, hydrate };
  })
  .views((self) => {
    // @@@@ need to manage these - there will be more than 1
    // and unsubscribe when this is destroyed
    let unsubFn;

    const get = (type, date) => {
      const id = `${type}|${date}`;
      const plan = self.plans.get(id);

      if (plan) return plan;

      const newPlan = PlanItem.create({ id, type, date });
      self.hydrate(newPlan);

      return newPlan;
    };

    return {
      get,
    };
  })
  /////////////////////////////
  // Add tasks to plans
  /////////////////////////////
  .actions((self) => {
    const addTaskToTodaysPlan = (item) => {
      const type = "daily"; // @@@@ constantise
      const today = utils.dates.today();
      const plan = self.get(type, today);

      plan.addTask(item);
    };

    const addTaskToThisWeeksPlan = (item) => {
      const type = "weekly"; // @@@@ constantise
      const today = utils.dates.today();
      const dateWeekCommenced = utils.dates.startOfWeek(today);
      const plan = self.get(type, dateWeekCommenced);

      plan.addTask(item);
    };

    return { addTaskToTodaysPlan, addTaskToThisWeeksPlan };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const isItemAlreadyInThisWeeksPlan = (item) => {
      const type = "weekly"; // @@@@ constantise
      const today = utils.dates.today();
      const dateWeekCommenced = utils.dates.startOfWeek(today);
      const id = `${type}|${dateWeekCommenced}`;
      let plan = self.plans.get(id);

      if (!plan) {
        return false;
      }

      return plan.containsItem(item);
    };

    const isItemAlreadyInTodaysPlan = (item) => {
      const type = "daily"; // @@@@ constantise
      const today = utils.dates.today();
      const id = `${type}|${today}`;
      let plan = self.plans.get(id);

      if (!plan) {
        return false;
      }

      return plan.containsItem(item);
    };

    const _datesSpread = (from, to) => {
      let workingDate = moment(from);
      const dates = [];

      while (moment(workingDate).isSameOrBefore(to)) {
        dates.push(workingDate.format(dateFormat));
        workingDate.add(1, "day");
      }

      return dates;
    };

    const getCombinedPlan = (date) => {
      const today = utils.dates.today();
      const dates = _datesSpread(date, today);
      const dailies = dates.map((d) => self.get("daily", d));
      const weekly = self.get("weekly", date);

      const plans = [weekly, ...dailies];

      const tasks = [];

      plans.forEach((p) =>
        p.tasks.forEach((t) => {
          // de-dupe
          if (!tasks.find((et) => et.id === t.id)) {
            tasks.push(t);
          }
        })
      );

      return { date, tasks };
    };

    return {
      isItemAlreadyInThisWeeksPlan,
      isItemAlreadyInTodaysPlan,
      getCombinedPlan,
    };
  });
