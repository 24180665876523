import { destroy, getParent, getSnapshot, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { firebaseUtils } from "../../../services/firebase.service";
import {
  calculateStatus,
  dateFormat,
  finishedChildren,
  getModelDepth,
  statusToColour,
  totalChildren,
} from "../../../services/utils";
import {
  addCompletedDate,
  clearCompletedDate,
  statusComplete,
  statusInProgress,
  statusNotStarted,
} from "../../../models/common";
import { PlanItem } from "../../../models/plans";
import { TagItem } from "../../tags/models/tags";
import { GoalItem } from "../../../models/goals";

/////////////////////////////
//
// Project item
//
/////////////////////////////
export const ProjectItem = types
  .model({
    id: types.identifier,
    index: 0, // only used for root projects
    title: types.string,
    status: statusNotStarted,
    description: "",
    parent: types.maybeNull(types.reference(types.late(() => ProjectItem))),
    goal: types.maybeNull(types.reference(types.late(() => GoalItem))),
    deliverables: types.array(types.reference(types.late(() => ProjectItem))),
    plans: types.array(types.reference(types.late(() => PlanItem))),
    tags: types.array(types.reference(types.late(() => TagItem))),
    completedAt: "",
  })
  /////////////////////////////
  // getters
  /////////////////////////////
  .views(() => ({
    get type() {
      return "project";
    },
  }))
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    // @@@@ DRY
    const statusPretty = (showNotStarted) => {
      switch (self.status) {
        case "not-started":
          return showNotStarted ? "not started" : "";

        default:
          return `${self.status}`;
      }
    };

    const statusColour = () => statusToColour(self.status);

    const showProgress = () => self.status === statusInProgress;

    const getProgress = () =>
      finishedChildren(self, "deliverables") /
      totalChildren(self, "deliverables");

    const getProgressSince = (dateFrom) => {
      const children = self.getDescendants();
      const childrenCompletedSince = children.filter((model) =>
        moment(model.completedAt, dateFormat).isSameOrAfter(dateFrom)
      );

      return `${childrenCompletedSince.length} deliverables completed this week`; // @@@@ THIS WEEK
    };

    const getRootDetails = () => {
      let model = self;

      while (model.parent) {
        model = model.parent;
      }

      const details = {
        type: self.type,

        projectId: model.id,
        projectTitle: model.title,

        goalId: model.goal?.id,
        goalTitle: model.goal?.title,
      };

      return details;
    };

    const hasChildren = () => self.deliverables.length > 0;

    return {
      statusPretty,
      statusColour,
      showProgress,
      getProgress,
      getProgressSince,
      getRootDetails,
      hasChildren,
    };
  })
  /////////////////////////////
  // lifecycle actions
  /////////////////////////////
  .actions((self) => {
    const beforeDestroy = () => {
      const userId = getParent(self, 3).id;
      const path = ["users", userId, "projects", self.id];
      firebaseUtils.deleteData(path);
    };

    const save = () => {
      const userId = getParent(self, 3).id;
      const snapshot = getSnapshot(self);

      // don't record the id field in the record
      const payload = { ...snapshot };
      delete payload.id;

      // only bother to store the index if this is a top level project
      if (payload.parent) delete payload.index;

      const path = ["users", userId, "projects", self.id];

      firebaseUtils.save(path, payload);
    };

    return { beforeDestroy, save };
  })
  /////////////////////////////
  // basic actions
  /////////////////////////////
  .actions((self) => {
    const addDeliverable = (id) => {
      self.deliverables.push(id);
      self.save();
    };

    const removeDeliverable = (id) => {
      const index = self.deliverables.findIndex(
        (deliverable) => deliverable.id === id
      );

      self.deliverables.splice(index, 1);
      self.save();
    };

    const setIndex = (index) => {
      self.index = index;
      self.save();
    };

    // currently there's no interface
    // to mark something complete as in progress
    // so don't need to worry about removing completedAt dates
    const markInProgress = () => {
      self.status = statusInProgress;
      self.save();

      const parent = self.parent;
      parent.updateStatus();
    };

    const markAsComplete = () => {
      self.status = statusComplete;
      addCompletedDate(self);
      self.save();

      const parent = self.parent;
      parent.updateStatus();
    };

    return {
      addDeliverable,
      removeDeliverable,
      setIndex,
      markInProgress,
      markAsComplete,
    };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const getPossibleParents = () => {
      const parentList = getParent(self, 2);

      return parentList.getPossibleParents(self);
    };

    const getDescendants = () => {
      const descendants = [];

      const addDescendants = (model) => {
        descendants.push(model);

        model.deliverables.forEach((deliverable) =>
          addDescendants(deliverable)
        );
      };

      self.deliverables.forEach((deliverable) => {
        addDescendants(deliverable);
      });

      return descendants;
    };

    const isHigherPriorityThan = (task) => {
      // these are not actually used yet
      if (task.goal) return false;
      if (task.chore) return true;

      const otherModel = task.project;

      const depthOtherModel = getModelDepth(otherModel);
      const depthThisModel = getModelDepth(self);

      let om = otherModel;
      let m = self;

      // bring the models to the same level
      if (depthOtherModel > depthThisModel) {
        let difference = depthOtherModel - depthThisModel;

        while (difference) {
          difference--;
          om = om.parent;
        }

        if (m === om) {
          // other model was a descendant of self
          return true;
        }
      } else if (depthThisModel > depthOtherModel) {
        let difference = depthThisModel - depthOtherModel;

        while (difference) {
          difference--;
          m = m.parent;
        }

        if (m === om) {
          // other model was a ancestor of self
          return false;
        }
      }

      // still going?
      // that means we're at the same level
      // with neither model being the ancestor of the other
      // so we keep going until we find their common ancestor
      let prevM = m;
      let prevOm = om;

      while (m.parent !== om.parent) {
        prevM = m;
        prevOm = om;

        m = m.parent;
        om = om.parent;
      }

      // now we finally have their common ancestor
      // which could be null, if the models come from different root projects
      if (m.parent) {
        const mIndex = m.parent.deliverables.findIndex((del) => del === prevM);
        const omIndex = m.parent.deliverables.findIndex(
          (del) => del === prevOm
        );

        return mIndex < omIndex;
      } else {
        return prevM.index < prevOm.index;
      }
    };

    return { getPossibleParents, getDescendants, isHigherPriorityThan };
  })
  /////////////////////////////
  // calculation actions
  /////////////////////////////
  .actions((self) => {
    const updateStatus = () => {
      const status = calculateStatus(self, "deliverables");

      if (status === self.status) {
        return;
      }

      const hasCompletionDate = self.completedAt;

      if (status === statusComplete && !hasCompletionDate) {
        addCompletedDate(self);
      } else if (status !== statusComplete && hasCompletionDate) {
        clearCompletedDate(self);
      }

      self.status = status;
      self.save();

      if (self.parent) {
        self.parent.updateStatus();
      }
    };

    return { updateStatus };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (plan) => {
      self.plans.push(plan);
      self.save();
    };

    const removePlan = (plan) => {
      const index = self.plans.findIndex((pl) => pl.id === plan);

      if (index < 0) {
        console.log("removePlan, failed to find plan", self.plans, plan);
        return;
      }

      self.plans.splice(index, 1);
      self.save();
    };

    // DRY
    const removeTag = (tag) => {
      const index = self.tags.findIndex((t) => t.id === tag.id);

      if (index > -1) {
        self.tags.splice(index, 1);
        self.save();
      }
    };

    const linkToGoal = (goal) => {
      if (self.goal) {
        console.log(
          "Attempted to link to a goal when this project already has a goal"
        );
        return;
      }

      self.goal = goal;
      self.save();
    };

    const unlinkFromGoal = (goal) => {
      if (self.goal !== goal) {
        console.log("Attempted to remove wrong goal in unlinkFromGoal()");
        return;
      }

      self.goal = null;
      self.save();
    };

    return { addPlan, removePlan, removeTag, linkToGoal, unlinkFromGoal };
  });

/////////////////////////////
//
// Project list
//
/////////////////////////////
export const ProjectList = types
  .model({
    projects: types.map(ProjectItem),
  })
  /////////////////////////////
  // simple views
  /////////////////////////////
  .views((self) => {
    const getProject = (modelId) => self.projects.get(modelId);

    const getProjects = () => Array.from(self.projects.values());

    const getRootProjects = () => getProjects().filter((p) => !p.parent);

    const getLinkableProjects = () => {
      const linkableProjects = self
        .getRootProjects()
        .filter((p) => !p.goal)
        .sort((a, b) => a.title.localeCompare(b.title));

      return linkableProjects;
    };

    const getBlankModel = (parentId) => ({
      title: "",
      description: "",
      deliverables: [],
      parent: parentId && { id: parentId },
      tags: [],
    });

    const hasCreatedAnyProjects = () => self.projects.size > 0;

    return {
      getProject,
      getProjects,
      getRootProjects,
      getLinkableProjects,
      getBlankModel,
      hasCreatedAnyProjects,
    };
  })
  .actions((self) => {
    // @@@@ set-up/use
    let unsubFn;

    const init = (userId) => {
      const path = ["users", userId, "projects"];
      // const orderBy = "level";
      const orderBy = false;

      console.log("Initialising projects");

      const promise = new Promise((resolve) => {
        const onLoad = () => resolve();

        unsubFn = firebaseUtils.subscribeCollection(
          path,
          self.hydrate,
          orderBy,
          onLoad
        );
      });

      return promise;
    };

    return { init };
  })
  /////////////////////////////
  // basic CRUD
  /////////////////////////////
  .actions((self) => {
    const create = (formData) => {
      console.log("Creating project:", formData);

      formData.id = uuidv4();

      if (formData.parent) {
        // save to MST
        self.projects.put(formData);

        const parentModel = self.projects.get(formData.parent);

        parentModel.addDeliverable(formData.id);
        parentModel.updateStatus();
      } else {
        // this is a top level project, so we need to add its initial index
        const numRootProjects = self.getRootProjects().length;
        formData.index = numRootProjects;

        // save to MST
        self.projects.put(formData);
      }

      if (formData.goal) {
        const user = getParent(self);
        const goal = user.getGoal(formData.goal);
        const backlinksRequired = false;

        goal.linkToProject(formData.id, backlinksRequired);
      }

      // save to DB
      const model = self.projects.get(formData.id);
      model.save();

      return formData.id;
    };

    const remove = (model) => {
      const deleteDeliverables = (model) => {
        // take a copy
        const deliverablesToDelete = [...model.deliverables];

        // remove the children en masse
        // this stops weird rendering things from happening
        model.deliverables = [];

        // unlink plans
        model.plans &&
          model.plans.forEach((plan) => plan.removeProject(model.id));

        // repeat
        deliverablesToDelete.forEach((deliverable) => {
          deleteDeliverables(deliverable);

          destroy(deliverable);
        });
      };

      // first remove this from the parent / root-index-list
      if (model.parent) {
        model.parent.removeDeliverable(model.id);
      } else {
        const rootProjects = self.getRootProjects();

        rootProjects
          .filter((m) => m.index > model.index)
          .forEach((m) => m.setIndex(m.index - 1));
      }

      // then delete all deliverables
      deleteDeliverables(model);

      // unlink from goal
      if (model.goal) {
        const backlinksRequired = false;

        model.goal.unlinkFromProject(model.id, backlinksRequired);
      }

      // @@@@ remember to unlink plans, once we have linking to plans

      // finally, do the delete
      destroy(model);
    };

    const hydrate = (data) => {
      self.projects.put(data);
    };

    const update = (formData) => {
      const modelPreUpdate = self.projects.get(formData.id);
      const parentsToUpdate = [];

      // handle changes to the parent
      // remembering that it's possible for these data
      // to not have a parent
      if (modelPreUpdate.parent?.id !== formData.parent) {
        if (formData.parent) {
          const newParentModel = self.projects.get(formData.parent);
          newParentModel.addDeliverable(formData.id);

          parentsToUpdate.push(newParentModel);
        }

        if (modelPreUpdate.parent?.id) {
          const priorParentModel = self.projects.get(modelPreUpdate.parent.id);
          priorParentModel.removeDeliverable(formData.id);

          parentsToUpdate.push(priorParentModel);
        }
      }

      // handle status changes
      if (modelPreUpdate.status !== formData.status) {
        if (formData.status === statusComplete) {
          addCompletedDate(formData);
        } else if (formData.completedAt) {
          clearCompletedDate(formData);
        }

        // if this project has a parent AND
        // the parent hasn't already been added because it changed
        // then we need to update the parent's status
        if (formData.parent && modelPreUpdate.parent?.id === formData.parent) {
          const parentModel = self.projects.get(formData.parent);

          parentsToUpdate.push(parentModel);
        }
      }

      // handle goal changes
      if (modelPreUpdate.goal?.id !== formData.goal) {
        // we're handling the project side of things
        const backlinksRequired = false;

        if (modelPreUpdate.goal) {
          // unlink from old
          modelPreUpdate.goal.unlinkFromProject(
            modelPreUpdate.id,
            backlinksRequired
          );
        }

        if (formData.goal) {
          // link to new
          // @@@@ note to self, using the user here is new and different
          // is it better/worse/indifferent to using the goalList??
          const user = getParent(self);
          const goal = user.getGoal(formData.goal);

          goal.linkToProject(formData.id, backlinksRequired);
        }
      }

      // save to MST
      self.projects.put(formData);

      // save to DB
      const model = self.projects.get(formData.id);
      model.save();

      parentsToUpdate.forEach((parentModel) => parentModel.updateStatus());
    };

    return { create, remove, hydrate, update };
  })
  /////////////////////////////
  // calculated updates
  /////////////////////////////
  .actions((self) => {
    const updateRootProjectOrder = (newList) => {
      newList.forEach((model, index) => model.setIndex(index));
    };

    return { updateRootProjectOrder };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const getPossibleParents = (model) => {
      const possibleList = self.getProjects();

      // remove the model itself
      const index = possibleList.findIndex((option) => option.id === model.id);

      possibleList.splice(index, 1);

      // remove any descendants
      const descendants = model.getDescendants();

      descendants.forEach((descendant) => {
        const index = possibleList.findIndex(
          (option) => option.id === descendant.id
        );

        possibleList.splice(index, 1);
      });

      return possibleList;
    };

    const getProjectsProgressedOutsideOfPlan = (plan) => {
      const dateFrom = plan.date;
      const projects = self.getProjects();
      const updatedProjects = projects.filter((model) =>
        moment(model.completedAt, dateFormat).isSameOrAfter(dateFrom)
      );

      // now remove anything that is in the plan, or an ancestor is in the plan
      const outOfPlanProjects = updatedProjects.filter((model) => {
        let found;
        let m = model;

        while (!found && m) {
          if (plan.tasks.find((task) => m === task.project)) {
            found = true;
          } else {
            m = m.parent;
          }
        }

        // we're interested in the ones that /aren't/ in the plan
        return !found;
      });

      // finally, group by common ancestors
      const ooppGrouped = [];

      outOfPlanProjects.forEach((m) => {
        let found = false;

        ooppGrouped.forEach((om, i) => {
          if (!found) {
            const ancestor = _findCommonAncestor(m, om);

            if (ancestor) {
              found = true;
              ooppGrouped[i] = ancestor;
            }
          }
        });

        if (!found) {
          ooppGrouped.push(m);
        }
      });

      return ooppGrouped;
    };

    // might be use for this elsewhere @@@@
    const _findCommonAncestor = (model1, model2) => {
      const depth1 = getModelDepth(model1);
      const depth2 = getModelDepth(model2);

      let m1 = model1;
      let m2 = model2;

      // bring the models to the same level
      if (depth1 > depth2) {
        let difference = depth1 - depth2;

        while (difference) {
          difference--;
          m1 = m1.parent;
        }

        if (m1 === m2) {
          return m1;
        }
      } else if (depth2 > depth1) {
        let difference = depth2 - depth1;

        while (difference) {
          difference--;
          m2 = m2.parent;
        }

        if (m1 === m2) {
          return m1;
        }
      }

      let prevM1 = m1;
      let prevM2 = m2;

      while (m1.parent !== m2.parent) {
        prevM1 = m1;
        prevM2 = m2;

        m1 = m1.parent;
        m2 = m2.parent;
      }

      return m1.parent;
    };

    // @@@@ REINSTATE
    // const getTopPriorityProject = () => {
    //   const rootProjects = self
    //     .getRootProjects()
    //     .sort((a, b) => a.index - b.index);
    //   let topProject;

    //   rootProjects.forEach((pr) => {
    //     if (!topProject && pr.status !== statusComplete) {
    //       topProject = pr;
    //     }
    //   });

    //   return topProject;
    // };

    // const getTopPriorityDeliverable = () => {
    //   const topProject = self.getTopPriorityProject();
    //   let topDeliverable = topProject;

    //   while (
    //     topDeliverable &&
    //     topDeliverable.status !== statusComplete &&
    //     topDeliverable.deliverables.length
    //   ) {
    //     let found = false;

    //     topDeliverable.deliverables.forEach((model) => {
    //       if (!found && model.status !== statusComplete) {
    //         found = true;
    //         topDeliverable = model;
    //       }
    //     });
    //   }

    //   return topDeliverable;
    // };

    const getProjectsWithTag = (tag) => {
      const projects = self.getProjects();

      const matches = projects.filter((model) =>
        model.tags.some((t) => t.id === tag.id)
      );

      return matches;
    };

    return {
      getPossibleParents,
      getProjectsProgressedOutsideOfPlan,
      getProjectsWithTag,
      // getTopPriorityProject,
      // getTopPriorityDeliverable,
    };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (projectId, planId) => {
      const model = self.projects.get(projectId);

      model.addPlan(planId);
    };

    const removePlan = (projectId, planId) => {
      const model = self.projects.get(projectId);

      model.removePlan(planId);
    };

    const removeTag = (tag) => {
      const projects = self.getProjects();

      projects.forEach((model) => model.removeTag(tag));
    };

    return { addPlan, removePlan, removeTag };
  });
