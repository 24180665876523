import React, { useContext } from "react";
import { Breadcrumb, Button } from "semantic-ui-react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import AppStateContext from "../contexts/appState";
import { Overview } from "../components/ui/elements";
import { useNavigate } from "react-router-dom";

const Section = styled.div`
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  max-width: 50ch;
`;

const SectionTitle = styled.h3`
  padding-bottom: 1rem;
`;

const Text = styled.p`
  padding-bottom: 1rem;
`;

const Settings = () => {
  const { userStore } = useContext(AppStateContext);
  const navigate = useNavigate();

  if (!userStore.userItem) {
    return null;
  }

  // init models
  const user = userStore.userItem;
  const subscribed = user.subscribedToNewsletter;

  const subscribeToNewsletter = () => user.subscribeToNewsletter();
  const unsubscribeFromNewsletter = () => user.unsubscribeFromNewsletter();

  const onLogout = () => {
    navigate("/log-in"); // @@@@ constantise

    userStore.logoutUser();
  };

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section>Settings</Breadcrumb.Section>
      </Breadcrumb>

      <Section>
        <SectionTitle>Email preferences</SectionTitle>
        {subscribed ? (
          <>
            <Text>
              You're subscribed to our monthly newsletter. If you're not finding
              it helpful you can unsubscribe here.
            </Text>
            <Button
              primary
              icon="mail"
              labelPosition="left"
              content="Unsubscribe me"
              onClick={unsubscribeFromNewsletter}
            />
          </>
        ) : (
          <>
            <Text>
              We'd love to send you useful updates via email. Our monthly
              newsletter covers new features, tips and tricks. You can
              unsubscribe at any time.
            </Text>
            <Button
              primary
              icon="mail"
              labelPosition="left"
              content="Subscribe me to the monthly newsletter"
              onClick={subscribeToNewsletter}
            />
          </>
        )}
      </Section>

      <Section>
        <SectionTitle>Logout</SectionTitle>
        <Button primary onClick={onLogout}>
          Logout
        </Button>
      </Section>
    </Overview>
  );
};

export default observer(Settings);
