import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";

import SharedListItem from "../ui/sharedListItem";
import TaskEditor from "./taskEditor";

const PlanEditor = ({
  user,
  plan,
  allGoals,
  allChores,
  allProjects,
  Header,
  Content,
  Actions,
}) => {
  const [taskCreatorOpen, setTaskCreatorOpen] = useState(false);

  const goalsForOptions = allGoals
    .filter((g) => g.parent)
    .map((g) => ({
      value: `goal:${g.id}`,
      key: `goal:${g.id}`,
      text: g.title,
    }));

  const choresForOptions = allChores.map((ch) => ({
    value: `chore:${ch.id}`,
    key: `chore:${ch.id}`,
    text: ch.title,
  }));

  const projectsForOptions = allProjects.map((p) => ({
    value: `project:${p.id}`,
    key: `project:${p.id}`,
    text: p.title,
  }));

  // @@@@ should I be using useMemo here?
  const optionsList = [
    ...goalsForOptions,
    ...projectsForOptions,
    ...choresForOptions,
  ];

  const addTask = (task) => {
    setTaskCreatorOpen(false);

    // massage into form that addTask is expecting
    // @@@@ improve this
    const item = task.goal
      ? { type: "goal", id: task.goal }
      : task.project
      ? { type: "project", id: task.project }
      : { type: "chore", id: task.chore };

    plan.addTask(item);
  };

  const removeTask = (task) => {
    plan.removeTask(task);
  };

  const openTaskCreator = () => {
    setTaskCreatorOpen(true);
  };

  const onCancel = () => {
    setTaskCreatorOpen(false);
  };

  return (
    <>
      <Header>Edit plan for {plan.label}</Header>

      <Content>
        {plan.tasks.map((task) => (
          <SharedListItem
            key={task.id}
            user={user}
            item={task.underlyingObject}
            cardView
            buttons={
              <Button
                content="Remove from plan"
                onClick={() => removeTask(task)}
              />
            }
          />
        ))}

        <Modal open={taskCreatorOpen} onClose={onCancel}>
          <TaskEditor
            addTask={addTask}
            optionsList={optionsList}
            Header={Modal.Header}
            Content={Modal.Content}
            Actions={Modal.Actions}
          />
        </Modal>

        {!plan.tasks.length && <p>No plan yet - add items to get started</p>}
      </Content>

      <Actions>
        <Button
          onClick={openTaskCreator}
          color={"green"}
          icon="add"
          content="Add an item"
          labelPosition="right"
        />
      </Actions>
    </>
  );
};

export default PlanEditor;
