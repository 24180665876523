import { types, flow } from "mobx-state-tree";

import { ChoresList } from "./chores";
import { GoalList } from "./goals";
import { PlanList } from "./plans";
import { ProjectList } from "../NEW-by-model/projects/models/projects";
import { TagsList } from "../NEW-by-model/tags/models/tags";

import { createCallable, firebaseUtils } from "../services/firebase.service";
import utils from "../services/utils";
import { statusComplete } from "./common";

export const UserItem = types
  .model({
    id: types.identifier,
    email: "",
    planList: types.optional(PlanList, {}),
    goalList: types.optional(GoalList, {}),
    projectList: types.optional(ProjectList, {}),
    choresList: types.optional(ChoresList, {}),
    tagsList: types.optional(TagsList, {}),
    name: types.optional(types.string, ""),
    _status: "loading",
    tutorialComplete: false,
    subscribedToNewsletter: false,
  })
  .actions((self) => {
    const init = flow(function* () {
      console.log("Initialising user");

      load();

      yield self.tagsList.init(self.id);
      yield self.goalList.init(self.id);
      yield self.projectList.init(self.id);
      yield self.choresList.init(self.id);
      yield self.planList.init(self.id);

      self.setReady();
    });

    const _hydrate = (userData) => {
      self.tutorialComplete = userData.tutorialComplete;
      self.subscribedToNewsletter = userData.subscribedToNewsletter;
    };

    // @@@@ set-up/use
    let unsubFn;

    const load = () => {
      console.log("Loading user");

      loadPrivateData();
      // loadSharedData();
    };

    const loadPrivateData = () => {
      const path = ["users", self.id];

      const onLoad = (data) => {
        self._hydrate(data);
      };

      const onError = () => {};

      unsubFn = firebaseUtils.subscribe(path, onLoad, onError);
    };

    // @@@@ see if triggersave is still needed
    const setTutorialComplete = (tutorialComplete, triggerSave = true) => {
      self.tutorialComplete = tutorialComplete;

      if (triggerSave) {
        const path = ["users", self.id];
        const payload = { tutorialComplete };

        console.log("saving tutorial completion for user");

        firebaseUtils.save(path, payload);
      }
    };

    const subscribeToNewsletter = () => {
      const subscribeUser = createCallable("subscribeUserToNewsletter");

      console.log("subscribing to newsletter");

      // DRY
      const path = ["users", self.id];
      const payload = { subscribedToNewsletter: true };

      firebaseUtils.save(path, payload);

      subscribeUser();
    };

    const unsubscribeFromNewsletter = () => {
      const unsubscribeUser = createCallable("unsubscribeUserFromNewsletter");

      console.log("unsubscribing from newsletter");

      // DRY
      const path = ["users", self.id];
      const payload = { subscribedToNewsletter: false };

      firebaseUtils.save(path, payload);

      unsubscribeUser();
    };

    return {
      setReady: () => {
        console.log("User initialised");
        self._status = "ready";
      },

      afterCreate: () => {
        init();
      },

      // User
      setTutorialComplete,
      _hydrate,
      subscribeToNewsletter,
      unsubscribeFromNewsletter,

      // Goals
      createGoal: self.goalList.create,
      updateGoal: self.goalList.update,
      deleteGoal: self.goalList.remove,
      updateGoalsOrder: self.goalList.updateGoalsOrder,

      // Projects
      createProject: self.projectList.create,
      updateProject: self.projectList.update,
      deleteProject: self.projectList.remove,
      updateRootProjectOrder: self.projectList.updateRootProjectOrder,

      // Chores
      createChore: self.choresList.create,
      updateChore: self.choresList.update,
      moveChore: self.choresList.move,
      deleteChore: self.choresList.remove,

      // Tags
      createTag: self.tagsList.create,
      updateTag: self.tagsList.update,
      deleteTag: self.tagsList.remove,
    };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const hasCompletedTutorial = () => {
      return self.tutorialComplete;
    };

    const hasPlanForThisWeek = () => {
      const today = utils.dates.today();
      const dateWeekCommenced = utils.dates.startOfWeek(today);
      const planForWeek = self.planList.get("weekly", dateWeekCommenced);

      return planForWeek?.tasks?.length > 0;
    };

    const hasPlanForToday = () => {
      const today = utils.dates.today();
      const planForToday = self.planList.get("daily", today);

      return planForToday?.tasks?.length > 0;
    };

    const hasCompletedPlanForThisWeek = () => {
      if (!self.hasPlanForThisWeek()) {
        return true; // redundantly true
      }

      const today = utils.dates.today();
      const dateWeekCommenced = utils.dates.startOfWeek(today);
      const planForWeek = self.planList.get("weekly", dateWeekCommenced);

      return (
        planForWeek.tasks.every((task) => task.status === statusComplete) > 0
      );
    };

    const hasCompletedPlanForToday = () => {
      if (!self.hasPlanForToday()) {
        return true; // redundantly true
      }

      const today = utils.dates.today();
      const planForToday = self.planList.get("daily", today);

      return (
        planForToday.tasks.every((task) => task.status === statusComplete) > 0
      );
    };

    return {
      hasCompletedTutorial,
      hasPlanForThisWeek,
      hasPlanForToday,
      hasCompletedPlanForThisWeek,
      hasCompletedPlanForToday,
    };
  })
  .views((self) => {
    // @@@@ review this list - are all still needed?
    return {
      // Cross-type
      getRelevantList: (type) => {
        let list;

        switch (type) {
          case "chore":
            list = self.choresList;
            break;

          case "goal":
            list = self.goalList;
            break;

          case "project":
            list = self.projectList;
            break;

          default:
            console.log("error in getRelevantList, unknown type:", type);
            list = null;
        }

        return list;
      },

      // Plans
      getDailyPlan: (date) => self.planList.get("daily", date),
      getWeeklyPlan: (date) => self.planList.get("weekly", date),
      getCombinedPlan: self.planList.getCombinedPlan,

      // @@@@ these are actions, not views!!
      addTaskToTodaysPlan: self.planList.addTaskToTodaysPlan,
      addTaskToThisWeeksPlan: self.planList.addTaskToThisWeeksPlan,

      isItemAlreadyInThisWeeksPlan: self.planList.isItemAlreadyInThisWeeksPlan,
      isItemAlreadyInTodaysPlan: self.planList.isItemAlreadyInTodaysPlan,

      // Goals
      getGoal: self.goalList.getGoal,
      getGoals: self.goalList.getGoals,
      getGoalsWithTag: self.goalList.getGoalsWithTag,
      getBlankGoal: self.goalList.getBlankModel,
      hasCreatedAnyGoals: self.goalList.hasCreatedAnyGoals,

      // Projects
      getProject: self.projectList.getProject,
      getProjects: self.projectList.getProjects,
      getProjectsWithTag: self.projectList.getProjectsWithTag,
      getRootProjects: self.projectList.getRootProjects,
      getLinkableProjects: self.projectList.getLinkableProjects,
      getBlankProject: self.projectList.getBlankModel,
      hasCreatedAnyProjects: self.projectList.hasCreatedAnyProjects,
      getProjectsProgressedOutsideOfPlan:
        self.projectList.getProjectsProgressedOutsideOfPlan,
      // getTopPriorityProject: self.projectList.getTopPriorityProject,
      // getTopPriorityDeliverable: self.projectList.getTopPriorityDeliverable,

      // Chores
      getChore: self.choresList.getChore,
      getChores: self.choresList.getChores,
      getChoresWithTag: self.choresList.getChoresWithTag,
      getFilteredChores: self.choresList.getFilteredChores,
      getNewChore: self.choresList.getNewChore,
      hasCreatedAnyChores: self.choresList.hasCreatedAnyChores,
      getChoresProgressedOutsideOfPlan:
        self.choresList.getChoresProgressedOutsideOfPlan,

      // Tags
      getTag: self.tagsList.getTag,
      getTags: self.tagsList.getTags,
      getBlankTag: self.tagsList.getBlankModel,
    };
  });
