import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Card, Button, Message, Header } from "semantic-ui-react";
import styled from "styled-components";

import { Overview } from "../ui/elements";
import SharedListItem from "../ui/sharedListItem";
import { linkTo } from "../../services/utils";
import { statusComplete } from "../../models/common";

// const Chore = styled.div`
//   padding-bottom: 0.2rem;
// `;

// const ChoreListItem = ({ chore }) => {
//   return (
//     <Chore>
//       {chore.title} {chore.statusPretty()}
//     </Chore>
//   );
// };

const ChoresOverview = ({ user, chores }) => {
  const navigate = useNavigate();

  const manageChores = () => navigate("/chores");

  const highValueChores = chores
    .filter(
      (chore) => chore.value === "high" && chore.status !== statusComplete
    )
    .slice(0, 5);

  const numChores = chores.length;
  const numHighValueChores = highValueChores.length;

  return (
    <Overview>
      <Header as="h2">Your Chores</Header>

      <Card>
        <Card.Content header="High value chores" />
        <Card.Content>
          {numHighValueChores ? (
            highValueChores.map((chore) => (
              <SharedListItem
                user={user}
                item={chore}
                key={chore.id}
                cardView
                linkTo={linkTo(chore)}
              />
            ))
          ) : (
            <Message warning>
              {numChores ? "No high value chores to display" : "No chores yet"}
            </Message>
          )}
        </Card.Content>
        <Card.Content extra>
          <Button primary onClick={manageChores}>
            Manage chores
          </Button>
        </Card.Content>
      </Card>
    </Overview>
  );
};

export default observer(ChoresOverview);
