import { useState } from "react";

export const useModal = (onSuccess, type) => {
  const [modalOpen, setModalOpen] = useState(false);

  const open = () => setModalOpen(true);

  const _onSuccess = (formData) => {
    setModalOpen(false);

    onSuccess(formData);
  };

  const onCancel = () => setModalOpen(false);

  return [
    modalOpen,
    { open, onSuccess: _onSuccess, onCancel, type, closeModal: onCancel },
  ];
};
