import UserStore from "./user.store";
import { firebaseService } from "../services/firebase.service";

class RootStore {
  constructor() {
    // services
    this.firebase = firebaseService(this);

    // stores
    this.userStore = new UserStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
