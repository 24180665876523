import React, { useContext } from "react";
import { Breadcrumb, Message } from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";
import { observer } from "mobx-react-lite";

import AppStateContext from "../contexts/appState";
import { GoalViewInProject, Overview } from "../components/ui/elements";
import utils, { dateFormat, linkTo } from "../services/utils";
import SharedListItem from "../components/ui/sharedListItem";
import {
  statusComplete,
  statusInProgress,
  statusNotStarted,
} from "../models/common";

const SubHeader = styled.h3`
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
`;

const Plan = styled.div`
  padding-bottom: 1rem;
  padding-left: 1rem;
`;

const PlanTitle = styled.h5`
  padding-top: 1rem;
  text-transform: uppercase;
  opacity: 0.7;
`;

const Group = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
`;

const GroupTitle = styled.h4``;

const Progress = () => {
  const { userStore } = useContext(AppStateContext);

  if (!userStore.userItem) {
    return null;
  }

  const user = userStore.userItem;

  const today = utils.dates.today();
  const dateWeekCommenced = utils.dates.startOfWeek(today);

  const planForWeek = user.getCombinedPlan(dateWeekCommenced);

  const projectsProgressedOutsideOfPlan =
    user.getProjectsProgressedOutsideOfPlan(planForWeek);

  const choresProgressedOutsideOfPlan =
    user.getChoresProgressedOutsideOfPlan(planForWeek);

  const plannedProjectsInGoals = [];
  const plannedProjectsOutsideGoals = [];
  const plannedChores = [];

  planForWeek.tasks.forEach((task) => {
    const { type, projectId, projectTitle, goalId, goalTitle } =
      task.getRootDetails();

    if (type === "chore") {
      plannedChores.push(task);
    } else {
      const projectList = goalId
        ? plannedProjectsInGoals
        : plannedProjectsOutsideGoals;

      const project = projectList.find((p) => p.projectId === projectId);

      if (project) {
        project.tasks.push(task);
      } else {
        projectList.push({
          projectId,
          projectTitle,
          goalTitle,
          tasks: [task],
        });
      }
    }
  });

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section>Progress</Breadcrumb.Section>
      </Breadcrumb>

      <SubHeader>Progress this week vs plan</SubHeader>

      {planForWeek.tasks?.length ? (
        <>
          <Group>
            <GroupTitle>Project work as part of goals</GroupTitle>

            {plannedProjectsInGoals.map((project) => (
              <Plan key={project.projectId}>
                <PlanTitle>Project: {project.projectTitle}</PlanTitle>

                {project.tasks.map((model) => (
                  <ListItem
                    user={user}
                    model={model.underlyingObject} // needed as these are taskItems
                    key={model.id}
                    startOfTimePeriod={dateWeekCommenced}
                    goal={project.goalTitle}
                  />
                ))}
              </Plan>
            ))}
          </Group>

          <Group>
            <GroupTitle>Project work outside of goals</GroupTitle>

            {plannedProjectsOutsideGoals.map((project) => (
              <Plan key={project.projectId}>
                <PlanTitle>Project: {project.projectTitle}</PlanTitle>

                {project.tasks.map((model) => (
                  <ListItem
                    user={user}
                    model={model.underlyingObject} // needed as these are taskItems
                    key={model.id}
                    startOfTimePeriod={dateWeekCommenced}
                    goal={project.goalTitle}
                  />
                ))}
              </Plan>
            ))}
          </Group>
          <Group>
            <GroupTitle>Chores</GroupTitle>

            <Plan>
              {plannedChores.map((model) => (
                <ListItem
                  user={user}
                  model={model.underlyingObject} // needed as these are taskItems
                  key={model.id}
                  startOfTimePeriod={dateWeekCommenced}
                />
              ))}
            </Plan>
          </Group>
        </>
      ) : (
        <Message warning>No plan made</Message>
      )}

      <SubHeader>Progress this week outside of plan</SubHeader>

      {projectsProgressedOutsideOfPlan.map((model) => (
        <ListItem
          key={model.id}
          user={user}
          model={model}
          startOfTimePeriod={dateWeekCommenced}
        />
      ))}

      {choresProgressedOutsideOfPlan.map((model) => (
        <ListItem
          key={model.id}
          user={user}
          model={model}
          startOfTimePeriod={dateWeekCommenced}
        />
      ))}
    </Overview>
  );
};

export default observer(Progress);

const Status = styled.div`
  font-size: 0.9rem;
  color: black;
  padding-top: 1rem;
`;

// in theory works for any timeframe
// but note the hardcoded word 'week'
// in the string below
const getStatusForTask = (task, dateFrom) => {
  switch (task.status) {
    case statusNotStarted:
      return "not started yet";

    case statusInProgress:
      if (task.hasChildren()) {
        return task.getProgressSince(dateFrom);
      }

      return "in progress";

    case statusComplete:
      if (moment(task.completedAt, dateFormat).isSameOrAfter(dateFrom)) {
        if (task.hasChildren()) {
          return task.getProgressSince(dateFrom);
        }

        return "completed this week";
      }

      return "completed previously";

    default:
      console.log("Unknown status in getStatusForTaskForWeek()");
      return "";
  }
};

const ListItem = ({ user, model, startOfTimePeriod, goal }) => {
  const link = linkTo(model);
  const status = getStatusForTask(model, startOfTimePeriod);
  const CustomStatus = <Status>{status}</Status>;

  // customStatus replaces the default status so can't just be passed as a child
  return (
    <SharedListItem
      user={user}
      item={model}
      linkTo={link}
      customStatus={CustomStatus}
    >
      {goal && <GoalViewInProject>&bull; Goal: {goal}</GoalViewInProject>}
    </SharedListItem>
  );
};
