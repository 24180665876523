import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Modal } from "semantic-ui-react";
import styled from "styled-components";

import AppStateContext from "../../../contexts/appState";
import {
  ActionsMenu,
  Overview,
  StyledMessage,
} from "../../../components/ui/elements";
import { useModal } from "../../../hooks/useModal";
import Editor from "../windows/Editor";
import SingleTag from "./SingleTag";

const TagItem = styled.div`
  background: white;
  color: black;
  display: flex;
  border: 1px solid grey;
  border-radius: 10px;
  width: 400px;
  align-items: center;
  margin-top: 1rem;
  margin-right: 0.5rem;

  cursor: pointer;
`;

const Title = styled.div`
  font-weight: bold;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
`;

const ColourBar = styled.div`
  background: ${(props) => props.colour};
  width: 1rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  display: flex;
  align-self: stretch;
`;

const Inner = styled.div`
  padding: 7px;
`;

// DRY
const ButtonsUnder = styled.div`
  border-top: 1px solid lightgrey;
  padding-top: 1rem;
`;

const Modals = styled.div``;

const TagsHome = () => {
  // set up
  const { userStore } = useContext(AppStateContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [creatorOpen, createContext] = useModal(userStore?.userItem?.createTag);
  const [editorOpen, editContext] = useModal(userStore?.userItem?.updateTag);

  const [model, setModel] = useState();

  if (!userStore.userItem) {
    return null;
  }

  // init models
  const user = userStore.userItem;

  const modelId = location.state?.tag;
  const tagsList = user.getTags();

  const blankModel = user.getBlankTag();

  // init UI
  const openEditor = (tag) => {
    setModel(tag);

    editContext.open();
  };

  const showTaggedItems = (tag) => {
    const options = { state: { tag: tag.id } };

    navigate(location.pathname, options);
  };

  // delete
  const onDelete = () => {
    editContext.closeModal();

    user.deleteTag(model);
  };

  if (modelId) {
    return <SingleTag modelId={modelId} user={user} />;
  }

  return (
    <Overview>
      <Breadcrumb size={"big"}>
        <Breadcrumb.Section
          as={Link}
          to={{ pathname: "/labels" }}
          state={{ tag: null }}
        >
          Manage Labels
        </Breadcrumb.Section>
      </Breadcrumb>

      {tagsList.map((tag) => (
        <TagItem key={tag.id}>
          <ColourBar colour={tag.colour} />

          <Inner>
            <Title>{tag.title}</Title>

            <ButtonsUnder>
              <Button
                key="edit"
                primary
                icon="edit"
                labelPosition="left"
                content="Edit"
                onClick={() => openEditor(tag)}
                size="mini"
              />

              <Button
                primary
                icon="eye"
                labelPosition="left"
                content="See related items"
                onClick={() => showTaggedItems(tag)}
                size="mini"
              />
            </ButtonsUnder>
          </Inner>
        </TagItem>
      ))}

      {tagsList.length === 0 && (
        <StyledMessage warning>No tags yet</StyledMessage>
      )}

      <ActionsMenu>
        <Button
          primary
          icon="add"
          labelPosition="left"
          content={"Create a label"}
          onClick={createContext.open}
        />
      </ActionsMenu>

      <Modals>
        <Modal open={creatorOpen} onClose={createContext.onCancel}>
          <Editor
            onSave={createContext.onSuccess}
            onCancel={createContext.onCancel}
            model={blankModel}
          />
        </Modal>

        <Modal open={editorOpen} onClose={editContext.onCancel}>
          <Editor
            onSave={editContext.onSuccess}
            onCancel={editContext.onCancel}
            onDelete={onDelete}
            model={model}
          />
        </Modal>
      </Modals>
    </Overview>
  );
};

export default observer(TagsHome);
