import React from "react";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 1rem;
  padding-right: max(calc((100vw - 1000px) / 2), 4rem);
  padding-left: max(calc((100vw - 1000px) / 2), 4rem);

  background: lightblue;
`;

const Logo = styled.div`
  font-size: 1.4rem;
  font-weight: bold;

  a {
    color: black;
  }
`;

const Nav = styled.div`
  display: flex;
  align-items: center;

  font-size: 1.2rem;
`;

const NavItem = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  a {
    color: black;
  }

  a.active {
    border-bottom: 1px solid;
  }
`;

const Login = styled.div`
  padding-left: 1rem;
`;

const AnonHeader = () => {
  const navigate = useNavigate();

  const gotoLogin = () => navigate("/log-in");

  return (
    <Header>
      <Logo>
        <Link to={"https://www.claritymate.app/"}>This is Clarity Mate</Link>
      </Logo>
      <Nav>
        <NavItem>
          <NavLink to={"https://www.claritymate.app/pricing"}>Pricing</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={"https://www.claritymate.app/blog"}>Blog</NavLink>
        </NavItem>
        <Login>
          <Button primary onClick={gotoLogin}>
            Log in
          </Button>
        </Login>
      </Nav>
    </Header>
  );
};

export default AnonHeader;
