import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import config from "../config/firebase";

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions();

const provider = new GoogleAuthProvider();

/////////////////////////////////////////
// Utils
/////////////////////////////////////////

const rootRef = "clarity-react/v1";

const pathToDoc = (path) => {
  const fullPath = [rootRef, ...path].join("/");

  return doc(db, fullPath);
};

const pathToCollection = (path) => {
  const fullPath = [rootRef, ...path].join("/");

  return collection(db, fullPath);
};

/////////////////////////////////////////
// firebase service
//
// used by the root store
/////////////////////////////////////////

export const firebaseService = (rootStore) => {
  const handleStateChange = (firebaseUser) => {
    if (firebaseUser) {
      const { email, uid, displayName } = firebaseUser;
      const user = { email, uid, displayName };

      rootStore.userStore.userLoggedIn(user);
    } else {
      rootStore.userStore.userLoggedOut();
    }
  };

  const registerEmailUser = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;

        console.log("user registered", user);

        return {};
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("user failed to register", errorCode, errorMessage);

        switch (errorCode) {
          case "auth/invalid-email":
            return { error: "Invalid email address" };

          case "auth/email-already-in-use":
            return { error: "That email address is already in use" };
        }

        return {
          error:
            "This is odd. Something weird happened. If it happens again please let us know!",
        };
      });
  };

  const signInWithGoogle = (onSuccess) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        handleStateChange(user);

        onSuccess && onSuccess();
      })
      .catch((error) => {
        const errorMessage = error.message;

        console.log(errorMessage);
      });
  };

  const signInEmailUser = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        console.log("user signed in", user);

        return {};
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("user failed to sign in", errorCode, errorMessage);

        switch (errorCode) {
          case "auth/invalid-login-credentials":
            return { error: "Invalid email address or password" };
        }

        return {
          error:
            "This is odd. Something weird happened. If it happens again please let us know!",
        };
      });
  };

  const requestPasswordReset = async (email) => {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("password reset sent");

        return {};
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("user failed to reset password", errorCode, errorMessage);

        switch (errorCode) {
          case "auth/invalid-email":
            return { error: "Invalid email address" };
        }

        return {
          error:
            "This is odd. Something weird happened. If it happens again please let us know!",
        };
      });
  };

  const watchForAuthStateChange = () => {
    onAuthStateChanged(auth, handleStateChange);
  };

  const logout = async () => {
    await signOut(auth);
  };

  return {
    registerEmailUser,
    signInWithGoogle,
    signInEmailUser,
    requestPasswordReset,
    watchForAuthStateChange,
    logout,
  };
};

const mockWatchForAuthStateChange = () => {};

const mocksignInWithGoogle = (onSuccess) => {
  onSuccess && onSuccess();
};

export const mockFirebaseService = {
  watchForAuthStateChange: mockWatchForAuthStateChange,
  signInWithGoogle: mocksignInWithGoogle,
};

/////////////////////////////////////////
// firebase utils
//
// probably needs combining with the service -- or just rename? This section does DB while the section above does auth
/////////////////////////////////////////

const fetchOnce = async (path) => {
  const docRef = pathToDoc(path);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? docSnap.data() : null;
};

const subscribe = (path, onData, onError) => {
  const docRef = pathToDoc(path);

  const unsubFn = onSnapshot(docRef, (snapshot) => {
    if (snapshot.exists()) {
      const payload = {
        id: snapshot.id,
        ...snapshot.data(),
        _status: "loaded",
      };

      onData(payload);
    } else {
      onError();
    }
  });

  return unsubFn;
};

const subscribeCollection = (path, onData, orderOn, onLoad, whereClause) => {
  const colRef = pathToCollection(path);
  let q = query(colRef);

  if (orderOn) {
    q = query(q, orderBy(orderOn));
  }

  if (whereClause) {
    q = query(q, where(...whereClause));
  }

  onSnapshot(q, (snapshot) => {
    // we only want to process the changes
    // we don't really want to reload every item in the list
    // just because one item changes
    snapshot.docChanges().forEach((change) => {
      // @@@@ handle deletes that come from the db
      if (change.type === "removed") {
        console.log("Removed item: ", change.doc.data());
        return;
      }

      const obj = { id: change.doc.id, ...change.doc.data() };

      onData(obj);
    });

    // *after* we've done all the callbacks
    // trigger what we are finished
    if (onLoad) {
      onLoad();
    }
  });

  // return unsubFn;
};

const save = (path, data) => {
  const docRef = pathToDoc(path);

  // merge records to help forwards compatibility
  setDoc(docRef, data, { merge: true });
};

const createReturningId = async (path, data) => {
  const colRef = pathToCollection(path);

  const docRef = await addDoc(colRef, data);

  return docRef.id;
};

const deleteData = (path) => {
  const docRef = pathToDoc(path);

  deleteDoc(docRef);
};

export const firebaseUtils = {
  fetchOnce,
  subscribe,
  subscribeCollection,
  save,
  createReturningId,
  deleteData,
};

/////////////////////////////////////////
//
// firebase functions
//
/////////////////////////////////////////

export const createCallable = (functionName) =>
  httpsCallable(functions, functionName);
