import { destroy, getParent, getSnapshot, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { firebaseUtils } from "../services/firebase.service";
import { dateFormat, statusToColour } from "../services/utils";
import {
  addCompletedDate,
  clearCompletedDate,
  statusComplete,
  statusInProgress,
} from "./common";
import { PlanItem } from "./plans";
import { TagItem } from "../NEW-by-model/tags/models/tags";

/////////////////////////////
//
// Chore item
//
/////////////////////////////
export const ChoreItem = types
  .model({
    id: types.identifier,
    title: types.string,
    isChallenging: false,
    status: "not-started", // for now
    value: "low",
    index: types.number,
    plans: types.array(types.reference(types.late(() => PlanItem))),
    tags: types.array(types.reference(types.late(() => TagItem))),
    completedAt: "",
  })
  /////////////////////////////
  // getters
  /////////////////////////////
  .views((self) => ({
    get type() {
      return "chore";
    },

    getRootDetails() {
      const details = { type: self.type };

      return details;
    },

    hasChildren() {
      return false;
    },
  }))
  /////////////////////////////
  // basic setters
  /////////////////////////////
  .actions((self) => {
    const markInProgress = () => {
      self.status = statusInProgress;

      self.save();
    };

    const markAsComplete = () => {
      // too many triggers to duplicate/handle
      // push this to the chores list to handle
      const parent = getParent(self, 2);

      self.status = statusComplete;

      parent.update(self);
    };

    const setIndex = (index) => {
      self.index = index;

      self.save();
    };

    const incrementIndex = () => {
      self.index++;

      self.save();
    };

    const decrementIndex = () => {
      self.index--;

      self.save();
    };

    return {
      markInProgress,
      markAsComplete,
      setIndex,
      incrementIndex,
      decrementIndex,
    };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    // @@@@ DRY
    const statusPretty = (showNotStarted) => {
      switch (self.status) {
        case "not-started":
          return showNotStarted ? "not started" : "";

        default:
          return `${self.status}`;
      }
    };

    const statusColour = () => statusToColour(self.status);

    const showProgress = () => false;

    return { statusPretty, statusColour, showProgress };
  })
  /////////////////////////////
  // lifecycle actions
  /////////////////////////////
  .actions((self) => {
    const beforeDestroy = () => {
      const userId = getParent(self, 3).id;
      const path = ["users", userId, "chores", self.id];

      firebaseUtils.deleteData(path);
    };

    const save = () => {
      const userId = getParent(self, 3).id;
      const snapshot = getSnapshot(self);
      const payload = { ...snapshot };
      const path = ["users", userId, "chores", self.id];

      // don't record the id field in the record
      delete payload.id;

      firebaseUtils.save(path, payload);
    };

    return { beforeDestroy, save };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (plan) => {
      self.plans.push(plan);
      self.save();
    };

    const removePlan = (plan) => {
      if (!self.plans) {
        console.log("removePlan, trying to remove plan from empty list");
        return;
      }

      const index = self.plans.findIndex((pl) => pl.id === plan);

      if (index < 0) {
        console.log("removePlan, failed to find plan", self.plans, plan);
        return;
      }

      self.plans.splice(index, 1);
      self.save();
    };

    // DRY
    const removeTag = (tag) => {
      const index = self.tags.findIndex((t) => t.id === tag.id);

      if (index > -1) {
        self.tags.splice(index, 1);
        self.save();
      }
    };

    return { addPlan, removePlan, removeTag };
  });

/////////////////////////////
//
// Chores List
//
/////////////////////////////
export const ChoresList = types
  .model({
    chores: types.map(ChoreItem),
  })
  .actions((self) => {
    // @@@@ set-up/use
    let unsubFn;

    const hydrate = (chore) => {
      self.chores.put(chore);
    };

    const init = (userId) => {
      const path = ["users", userId, "chores"];
      const orderBy = "index";

      console.log("Initialising chores");

      const hydrateChore = (chore) => {
        self.hydrate(chore);
      };

      const promise = new Promise((resolve) => {
        const onLoad = () => {
          resolve();
        };

        unsubFn = firebaseUtils.subscribeCollection(
          path,
          hydrateChore,
          orderBy,
          onLoad
        );
      });

      return promise;
    };

    const _insert = (chore, skipOne = false) => {
      const value = chore.value;
      const modelList = self.getChores();

      const numHighValueChores = self.getNumberOfHighValueChores();
      const numHighOrMediumValueChores =
        numHighValueChores + self.getNumberofMediumValueChores();
      const numOpenChores = self.getNumberofOpenChores();

      if (value === "low" || value === undefined) {
        // this can just go at the end
        chore.index = numOpenChores;
      } else {
        const newIndex =
          value === "high" ? numHighValueChores : numHighOrMediumValueChores;

        chore.index = newIndex;

        // we need to correct for kinda unavoidable double-counting
        if (skipOne) {
          chore.index--;
        }

        // now bump everything below down one
        const choresToUpdate = modelList.filter(
          (ch) => ch.index >= chore.index
        );

        choresToUpdate.forEach((ch) => ch.incrementIndex());
      }
    };

    const create = (chore) => {
      chore.id = uuidv4();

      // this sets the chore's index
      self._insert(chore);

      // save to MST
      hydrate(chore);

      // save to DB
      const choreModel = self.chores.get(chore.id);
      choreModel.save();

      return chore.id;
    };

    const update = (updatedChore) => {
      const chore = self.chores.get(updatedChore.id);

      const { value: origValue } = chore;

      const {
        status: newStatus,
        completedAt: hasCompletionDate,
        value: newValue,
        index,
      } = updatedChore;

      const indexNotSet = -1;

      if (newStatus === statusComplete && !hasCompletionDate) {
        addCompletedDate(updatedChore);
      } else if (newStatus !== statusComplete && hasCompletionDate) {
        clearCompletedDate(updatedChore);
      }

      // deal with index pain if chore has just been completed/uncompleted
      if (newStatus === statusComplete && index !== indexNotSet) {
        updatedChore.index = indexNotSet;

        // move following chores up in the index order -- DRY @@@@
        const modelList = self.getChores();
        const choresToUpdate = modelList.filter((ch) => ch.index > index);

        choresToUpdate.forEach((ch) => {
          ch.decrementIndex();
        });
      } else if (newStatus !== statusComplete && index === indexNotSet) {
        self._insert(updatedChore);
      } else if (newValue !== origValue) {
        // value has changed
        // first update anything that needs bumping up
        const modelList = self.getChores();
        const choresToUpdate = modelList.filter((ch) => ch.index > index);

        choresToUpdate.forEach((ch) => {
          ch.decrementIndex();
        });

        // then move this one
        // the skip logic is necessary
        // as chores moving 'downwards' in value
        // will be counting themselves twice in the _insert method
        const valuesByNum = { high: 0, medium: 1, low: 2 };
        const skipOne = valuesByNum[newValue] > valuesByNum[origValue];

        self._insert(updatedChore, skipOne);
      }

      // save to MST
      hydrate(updatedChore);

      // save to DB
      const choreModel = self.chores.get(updatedChore.id);
      choreModel.save();
    };

    const move = (
      movedChoreId,
      sourceValue,
      destValue,
      sourceIndex,
      destIndex
    ) => {
      if (sourceValue === destValue && sourceIndex === destIndex) {
        return;
      }

      const chore = self.chores.get(movedChoreId);

      // sourceIndex and destIndex are both indexed from
      // the first chore with that value
      // so first of all we need to convert them into
      // being indexed from the first chore
      const numHighValueChores = self.getNumberOfHighValueChores();
      const numMediumValueChores = self.getNumberofMediumValueChores();

      let trueSourceIndex = sourceIndex;
      let trueDestIndex = destIndex;

      if (sourceValue === "medium") {
        trueSourceIndex += numHighValueChores;
      } else if (sourceValue === "low") {
        trueSourceIndex += numHighValueChores + numMediumValueChores;
      }

      if (destValue === "medium") {
        trueDestIndex += numHighValueChores;
      } else if (destValue === "low") {
        trueDestIndex += numHighValueChores + numMediumValueChores;
      }

      // need to dec the source as we counted it earlier
      // when calculating the size of the 'tranches'
      if (destValue !== sourceValue && trueDestIndex > trueSourceIndex) {
        trueDestIndex--;
      }

      // update the indexes
      // are we shifting up or down?
      if (trueDestIndex > trueSourceIndex) {
        self.chores.forEach((ch) => {
          if (ch.index > trueSourceIndex && ch.index <= trueDestIndex) {
            ch.setIndex(ch.index - 1);
          }
        });
      } else {
        self.chores.forEach((ch) => {
          if (ch.index >= trueDestIndex && ch.index < trueSourceIndex) {
            ch.setIndex(ch.index + 1);
          }
        });
      }

      // don't forget to update the status!
      chore.value = destValue;
      chore.setIndex(trueDestIndex); // this triggers a save
    };

    const remove = (chore) => {
      // remove self from any linked plans
      chore.plans && chore.plans.forEach((plan) => plan.removeChore(chore.id));

      // move following chores up in the index order
      const choresArray = Array.from(self.chores.values());
      const choresToUpdate = choresArray.filter((ch) => ch.index > chore.index);

      choresToUpdate.forEach((ch) => {
        ch.decrementIndex();
      });

      destroy(chore);
    };

    return { init, hydrate, _insert, create, update, move, remove };
  })
  /////////////////////////////
  // linking/unlinking actions
  /////////////////////////////
  .actions((self) => {
    const addPlan = (choreId, planId) => {
      const chore = self.chores.get(choreId);
      chore.addPlan(planId);
    };

    const removePlan = (choreId, planId) => {
      const chore = self.chores.get(choreId);
      chore.removePlan(planId);
    };

    const removeTag = (tag) => {
      const chores = self.getChores();

      chores.forEach((model) => model.removeTag(tag));
    };

    return { addPlan, removePlan, removeTag };
  })
  /////////////////////////////
  // simple views
  /////////////////////////////
  .views((self) => {
    const getChore = (modelId) => self.chores.get(modelId);

    return { getChore };
  })
  /////////////////////////////
  // calculated views
  /////////////////////////////
  .views((self) => {
    const getChores = () => {
      return Array.from(self.chores.values()).sort((a, b) => a.index - b.index);
    };

    // this mostly exists so that React doesn't complain
    // about controlled <-> uncontrolled components
    const getNewChore = () => {
      const newChore = { title: "", status: "not-started", tags: [] };

      return newChore;
    };

    const getChoresWithTag = (tag) => {
      const chores = self.getChores();

      const matches = chores.filter((model) =>
        model.tags.some((t) => t.id === tag.id)
      );

      return matches;
    };

    const getFilteredChores = (showCompleted) => {
      let chores = getChores();

      chores = chores.filter((ch) => (ch.status === "done") === showCompleted);

      return chores;
    };

    const hasCreatedAnyChores = () => {
      return self.chores.size > 0;
    };

    const getChoresProgressedOutsideOfPlan = (plan) => {
      const dateFrom = plan.date;
      const chores = self.getChores();
      const updatedChores = chores.filter((model) =>
        moment(model.completedAt, dateFormat).isSameOrAfter(dateFrom)
      );

      // now remove anything that is in the plan
      const outOfPlanChores = updatedChores.filter((model) => {
        const found = plan.tasks.find((task) => model === task.chore);

        // we're interested in the ones that /aren't/ in the plan
        return !found;
      });

      return outOfPlanChores;
    };

    const getNumberOfHighValueChores = () => {
      const modelsList = self.getChores();

      const numHighValueChores = modelsList.filter(
        (ch) =>
          ch.value === "high" &&
          // ignore completed chores
          ch.status !== statusComplete
      ).length;

      return numHighValueChores;
    };

    const getNumberofMediumValueChores = () => {
      const modelsList = self.getChores();

      const numMediumValueChores = modelsList.filter(
        (ch) =>
          ch.value === "medium" &&
          // ignore completed chores
          ch.status !== statusComplete
      ).length;

      return numMediumValueChores;
    };

    const getNumberofOpenChores = () => {
      const modelsList = self.getChores();

      const numOpenChores = modelsList.filter(
        (ch) => ch.status !== statusComplete
      ).length;

      return numOpenChores;
    };

    return {
      getChores,
      getNewChore,
      getChoresWithTag,
      getFilteredChores,
      hasCreatedAnyChores,
      getChoresProgressedOutsideOfPlan,
      getNumberOfHighValueChores,
      getNumberofMediumValueChores,
      getNumberofOpenChores,
    };
  });
