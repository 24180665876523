import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

import { statusOptionsForDropdown as statusOptions } from "../../../models/common";

const { Header, Content, Actions } = Modal;

// passing user through for now. Maybe come back and remove it later? @@@@
const Editor = ({ onSave, onCancel, onDelete, model, tags, user }) => {
  const [formData, setFormData] = useState({
    ...model,
    tags: model.tags.map((tag) => tag.id),
    parent: model.parent?.id,
    goal: model.goal?.id,
  });

  // init UI
  const isNew = !model.id;
  const allowEditParentProject = !isNew && !formData.goal;
  const allowEditGoal = !formData.parent;

  const title = isNew
    ? model.parent
      ? "Create new deliverable"
      : "Create new project"
    : model.parent
    ? "Edit deliverable"
    : "Edit project";

  const statusLabel = model.deliverables?.length
    ? "Status - calculated from deliverables"
    : "Status";

  // init models
  const [parentInfo, setParentInfo] = useState({
    value: "",
    options: [],
  });

  const tagOptions = tags.map((tag) => ({
    key: tag.id,
    text: tag.title,
    value: tag.id,
  }));

  const goals = user.getGoals().sort((a, b) => a.title.localeCompare(b.title));

  const goalOptions = goals.map((goal) => ({
    key: goal.id,
    text: goal.title,
    value: goal.id,
  }));

  useEffect(() => {
    if (!isNew) {
      const possibleParents = model.getPossibleParents();
      const options = possibleParents.map((pp) => ({
        value: pp.id,
        key: pp.id,
        text: pp.title,
      }));

      setParentInfo({ value: model.parent?.id, options });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // clear up any vestigal strings
    // which are created when semantic ui clears a field
    if (formData.goal === "") {
      formData.goal = null;
    }

    if (formData.parent === "") {
      formData.parent = null;
    }

    onSave(formData);
  };

  const handleDelete = (event) => {
    event.preventDefault();

    const confirmed = window.confirm(
      `Would you like to delete ${formData.title}?`
    );

    if (confirmed) {
      onDelete(formData);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();

    onCancel();
  };

  const onTitleUpdate = (_event, { value }) => {
    const updatedData = { ...formData, title: value };

    setFormData(updatedData);
  };

  const onStatusUpdate = (_event, { value }) => {
    const updatedData = { ...formData, status: value };

    setFormData(updatedData);
  };

  const onDescriptionUpdate = (_event, { value }) => {
    const updatedData = { ...formData, description: value };

    setFormData(updatedData);
  };

  const onParentUpdate = (_event, { value }) => {
    const updatedData = { ...formData, parent: value };

    setParentInfo((info) => ({ ...info, value })); // @@@@ is this needed? Goals seem to work without this level of complexity???
    setFormData(updatedData);
  };

  const onGoalUpdate = (_event, { value }) => {
    const updatedData = { ...formData, goal: value };

    setFormData(updatedData);
  };

  const onTagsUpdate = (_event, { value }) => {
    const updatedData = { ...formData, tags: value };

    setFormData(updatedData);
  };

  const onTagCreated = (_event, { value }) => {
    const newTagId = user.createTag({ title: value });
    const updatedData = { ...formData, tags: [...formData.tags, newTagId] };

    setFormData(updatedData);
  };

  return (
    <>
      <Header>{title}</Header>

      <Content>
        <Form onSubmit={handleSubmit}>
          {allowEditGoal && (
            <Form.Select
              clearable
              fluid
              search
              options={goalOptions}
              placeholder="Select goal"
              value={formData.goal}
              onChange={onGoalUpdate}
              label={{
                children: "Linked Goal (optional)",
                htmlFor: "form-select-goal",
              }}
              searchInput={{ id: "form-select-goal" }}
            />
          )}

          {allowEditParentProject && (
            <Form.Select
              clearable
              fluid
              search
              options={parentInfo.options}
              placeholder="Select parent"
              value={parentInfo.value}
              onChange={onParentUpdate}
              label={{
                children: "Parent Project",
                htmlFor: "form-select-parent",
              }}
              searchInput={{ id: "form-select-parent" }}
            />
          )}

          <Form.Input
            fluid
            required
            label="Title"
            placeholder="Title"
            id="form-input-title"
            value={formData.title}
            onChange={onTitleUpdate}
          />

          <Form.Select
            fluid
            search
            options={statusOptions}
            label={{ children: statusLabel, htmlFor: "form-select-status" }}
            placeholder="Status"
            searchInput={{ id: "form-select-status" }}
            value={formData.status}
            onChange={onStatusUpdate}
            disabled={!!model.deliverables?.length}
          />

          <Form.TextArea
            label="Description"
            placeholder="Description"
            value={formData.description}
            onChange={onDescriptionUpdate}
          />

          <Form.Select
            fluid
            multiple
            search
            clearable
            allowAdditions
            options={tagOptions}
            label={{
              children: "Labels (optional)",
              htmlFor: "form-select-tags",
            }}
            placeholder="Add label(s)"
            searchInput={{ id: "form-select-tags" }}
            value={formData.tags}
            onChange={onTagsUpdate}
            onAddItem={onTagCreated}
          />
        </Form>
      </Content>

      <Actions>
        <Button primary onClick={handleSubmit}>
          Save
        </Button>

        {!isNew && (
          <Button negative onClick={handleDelete}>
            Delete
          </Button>
        )}

        <Button className="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      </Actions>
    </>
  );
};

export default Editor;
