import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

import { valueOptionsForDropdown as valueOptions } from "../../models/common";
import { statusOptionsForDropdown as statusOptions } from "../../models/common";

const { Header, Content, Actions } = Modal;

// @@@@ bring title in here
const ChoresEditor = ({
  title,
  model,
  onSave,
  onCancel,
  onDelete,
  tags,
  user,
}) => {
  const [formData, setFormData] = useState({
    ...model,
    tags: model.tags.map((tag) => tag.id),
  });

  const onSubmit = (event) => {
    event.preventDefault();

    onSave(formData);
  };

  const cancel = (event) => {
    event.preventDefault();

    onCancel();
  };

  // @@@@ DRY UP forms everywhere
  const onTitleUpdate = (_event, { value }) => {
    const updatedData = { ...formData, title: value };

    setFormData(updatedData);
  };

  const onStatusUpdate = (_event, { value }) => {
    const updatedData = { ...formData, status: value };

    setFormData(updatedData);
  };

  const onValueUpdate = (_event, { value }) => {
    const updatedData = { ...formData, value };

    setFormData(updatedData);
  };

  const onTagsUpdate = (_event, { value }) => {
    const updatedData = { ...formData, tags: value };

    setFormData(updatedData);
  };

  const onTagCreated = (_event, { value }) => {
    const newTagId = user.createTag({ title: value });
    const updatedData = { ...formData, tags: [...formData.tags, newTagId] };

    // don't forget to add to list - but is it needed??

    setFormData(updatedData);
  };

  const deleteChore = (event) => {
    event.preventDefault();

    const confirmed = window.confirm(
      `Would you like to delete ${chore.title}?`
    );

    if (confirmed) {
      onDelete(formData);
    }
  };

  // init UI
  const isNew = !model.id;

  const tagOptions = tags.map((tag) => ({
    key: tag.id,
    text: tag.title,
    value: tag.id,
  }));

  return (
    <>
      <Header>{title}</Header>

      <Content>
        <Form onSubmit={onSubmit}>
          <Form.Input
            fluid
            required
            label="Title"
            placeholder="Title"
            id="form-input-title"
            value={formData.title}
            onChange={onTitleUpdate}
          />

          <Form.Select
            fluid
            search
            options={valueOptions}
            label={{ children: "Value", htmlFor: "form-select-value" }}
            placeholder="Value"
            searchInput={{ id: "form-select-value" }}
            value={formData.value}
            onChange={onValueUpdate}
          />

          <Form.Select
            fluid
            search
            options={statusOptions}
            label={{ children: "Status", htmlFor: "form-select-status" }}
            placeholder="Status"
            searchInput={{ id: "form-select-status" }}
            value={formData.status}
            onChange={onStatusUpdate}
          />

          <Form.Select
            fluid
            multiple
            search
            clearable
            allowAdditions
            options={tagOptions}
            label={{
              children: "Labels (optional)",
              htmlFor: "form-select-tags",
            }}
            placeholder="Add label(s)"
            searchInput={{ id: "form-select-tags" }}
            value={formData.tags}
            onChange={onTagsUpdate}
            onAddItem={onTagCreated}
          />
        </Form>
      </Content>

      <Actions>
        <Button primary onClick={onSubmit}>
          Save
        </Button>
        {!isNew && (
          <Button negative onClick={deleteChore}>
            Delete
          </Button>
        )}
        <Button onClick={cancel}>Cancel</Button>
      </Actions>
    </>
  );
};

export default ChoresEditor;
