import moment from "moment";

import { dateFormat } from "../services/utils";

export const statusOptions = [
  { value: "not-started", label: "Not Started" },
  { value: "ongoing", label: "Ongoing" },
  { value: "done", label: "Done" },
];

// use this above @@@@
export const statusComplete = "done";
export const statusInProgress = "ongoing";
export const statusNotStarted = "not-started";

export const statusOptionsForDropdown = statusOptions.map((opt) => ({
  value: opt.value,
  key: opt.value,
  text: opt.label,
}));

const valueOptions = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];

export const valueOptionsForDropdown = valueOptions.map((opt) => ({
  value: opt.value,
  key: opt.value,
  text: opt.label,
}));

/////////////////////////////
// work with completed dates
/////////////////////////////
export const addCompletedDate = (item) => {
  const today = new Date();
  const todayDate = moment(today).format(dateFormat);

  item.completedAt = todayDate;
};

export const clearCompletedDate = (item) => {
  // deleting the field sometimes, but not always, upsets
  // mobx, so let's blank it instead
  item.completedAt = "";
};

export const colourPalette = [
  "#490092",
  "#006ddb",
  "#b66dff",
  "#ff6db6",
  "#920000",
  "#8f4e00",
  "#db6d00",
  "#ffdf4d",
];
