import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";

import { colourPalette } from "../../../models/common";

const { Header, Content, Actions } = Modal;

const Picker = styled.div`
  width: 300px;
  background: #f0f0f4;
  border-radius: 9px;

  .react-colorful {
    width: auto;
  }
`;

const Swatches = styled.div`
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
`;

const Swatch = styled.button`
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
`;

const Editor = ({ onSave, onCancel, onDelete, model }) => {
  const [formData, setFormData] = useState({
    ...model,
  });

  // init UI
  const isNew = !model.id;

  const title = isNew ? "Create a label" : "Edit label";

  const handleSubmit = (event) => {
    event.preventDefault();

    onSave(formData);
  };

  const handleDelete = (event) => {
    event.preventDefault();

    const confirmed = window.confirm(
      `Would you like to delete ${formData.title}?`
    );

    if (confirmed) {
      onDelete(formData);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();

    onCancel();
  };

  const onTitleUpdate = (_event, { value }) => {
    const updatedData = { ...formData, title: value };

    setFormData(updatedData);
  };

  const onColourUpdate = (colour, event) => {
    const updatedData = { ...formData, colour };

    // only get an event via the buttons
    // not via the picker
    event && event.preventDefault();

    setFormData(updatedData);
  };

  return (
    <>
      <Header>{title}</Header>

      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            fluid
            required
            label="Title"
            placeholder="Title"
            id="form-input-title"
            value={formData.title}
            onChange={onTitleUpdate}
          />

          {/* this is to pick up the css from semantic UI */}
          <div className="field">
            <label htmlFor="form-input-colour">Colour</label>
            <Picker id="form-input-colour">
              <HexColorPicker
                color={formData.colour}
                onChange={onColourUpdate}
              />

              <Swatches>
                {colourPalette.map((colour) => (
                  <Swatch
                    key={colour}
                    style={{ background: colour }}
                    onClick={(event) => onColourUpdate(colour, event)}
                  />
                ))}
              </Swatches>
            </Picker>
          </div>
        </Form>
      </Content>

      <Actions>
        <Button primary onClick={handleSubmit}>
          Save
        </Button>

        <Button negative onClick={handleDelete}>
          Delete
        </Button>

        <Button className="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      </Actions>
    </>
  );
};

export default Editor;
