import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import styled from "styled-components";

import { useModal } from "../../hooks/useModal";
import SharedListItem from "../ui/sharedListItem";

const { Header, Content, Actions } = Modal;

const Modals = styled.div``;

const GoalLinker = ({
  model,
  user,
  linkToProject,
  unlinkFromProject,
  projects,
}) => {
  const [linkerOpen, link] = useModal(linkToProject);

  // init models
  const projectList = model.projects.map((p) => p.id);

  return (
    <>
      <Header>Add/remove linked projects</Header>

      <Content>
        {projectList.map((project) => (
          <SharedListItem
            key={project}
            user={user}
            item={user.getProject(project)}
            cardView
            buttons={
              <Button
                content="Unlink"
                onClick={() => unlinkFromProject(project)}
              />
            }
          />
        ))}

        {!projectList.length && <p>No linked projects</p>}
      </Content>

      <Actions>
        <Button
          onClick={link.open}
          color={"green"}
          icon="add"
          content="Link to a project"
          labelPosition="right"
        />
      </Actions>

      <Modals>
        <Modal open={linkerOpen} onClose={link.onCancel}>
          <Linker onSave={link.onSuccess} projects={projects} />
        </Modal>
      </Modals>
    </>
  );
};

export default GoalLinker;

const Linker = ({ onSave, projects }) => {
  const [project, setProject] = useState(null);

  const optionsList = projects.map((p) => ({
    value: p.id,
    key: p.id,
    text: p.title,
  }));

  const onProjectUpdate = (_event, { value }) => {
    setProject(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    onSave(project);
  };

  return (
    <>
      <Header>Link to a project</Header>

      <Content>
        <Form>
          <Form.Group>
            <Form.Select
              fluid
              search
              options={optionsList}
              placeholder="Choose a project"
              onChange={onProjectUpdate}
              label={{
                children: "Choose a project",
                htmlFor: "form-select-project",
              }}
              searchInput={{ id: "form-select-project" }}
              width={8}
            />
          </Form.Group>
        </Form>
      </Content>

      <Actions>
        <Button
          color="green"
          content="Link"
          icon="add"
          labelPosition="right"
          disabled={!project}
          onClick={onSubmit}
        />
      </Actions>
    </>
  );
};
