const config = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  apiKey: "AIzaSyAOIb20caNSdUVCF7fAd8NxR6z_SMOzJ4s",
  authDomain: "clarity-1f034.firebaseapp.com",
  databaseURL: "https://clarity-1f034.firebaseio.com",
  projectId: "clarity-1f034",
  storageBucket: "clarity-1f034.appspot.com",
  messagingSenderId: "933249280455",
  appId: "1:933249280455:web:4f4e0764193aee77efd1e2"
};

export default config;
