import { action, flow, makeObservable, observable } from "mobx";

import { UserItem } from "../models/user";
import { firebaseFunctions, firebaseUtils } from "../services/firebase.service";

class userStore {
  authUser;
  userItem;

  constructor(rootStore) {
    this.rootStore = rootStore; // @@@@ is this used??
    this.authUser = null;
    this.userItem = null;

    makeObservable(this, {
      authUser: observable,
      userItem: observable,
      userLoggedIn: action,
      userLoggedOut: action,
      logoutUser: action,
    });
  }

  userLoggedIn = (authUser) => {
    this.authUser = authUser;
    this.userItem = UserItem.create({
      id: authUser.uid,
      email: authUser.email,
    });
  };

  userLoggedOut = () => {
    this.authUser = null;
    this.userItem = null;
  };

  logoutUser = () => this.rootStore.firebase.logout();
}

export default userStore;
