import { destroy, getParent, getSnapshot, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
import { firebaseUtils } from "../../../services/firebase.service";
import { colourPalette } from "../../../models/common";

/////////////////////////////
//
// Tag item
//
/////////////////////////////
export const TagItem = types
  .model({
    id: types.identifier,
    title: types.string,
    colour: colourPalette[0],
  })
  /////////////////////////////
  // lifecycle actions
  /////////////////////////////
  .actions((self) => {
    const beforeDestroy = () => {
      const userId = getParent(self, 3).id;
      const path = ["users", userId, "tags", self.id];

      firebaseUtils.deleteData(path);
    };

    const save = () => {
      const userId = getParent(self, 3).id;
      const snapshot = getSnapshot(self);

      // don't record the id field in the record
      const payload = { ...snapshot };
      delete payload.id;

      const path = ["users", userId, "tags", self.id];

      firebaseUtils.save(path, payload);
    };

    return { beforeDestroy, save };
  });

/////////////////////////////
//
// Tags List
//
/////////////////////////////
export const TagsList = types
  .model({
    tags: types.map(TagItem),
  })
  /////////////////////////////
  // simple views
  /////////////////////////////
  .views((self) => {
    const getTag = (modelId) => self.tags.get(modelId);

    const getTags = () => Array.from(self.tags.values());

    const getBlankModel = () => ({
      title: "",
      colour: colourPalette[0],
    });

    return { getTag, getTags, getBlankModel };
  })
  .actions((self) => {
    // @@@@ set-up/use
    let unsubFn;

    const init = (userId) => {
      const path = ["users", userId, "tags"];
      const orderBy = false;

      console.log("Initialising tags");

      const promise = new Promise((resolve) => {
        const onLoad = () => resolve();

        unsubFn = firebaseUtils.subscribeCollection(
          path,
          self.hydrate,
          orderBy,
          onLoad
        );
      });

      return promise;
    };

    return { init };
  })
  /////////////////////////////
  // basic CRUD
  /////////////////////////////
  .actions((self) => {
    const create = (formData) => {
      console.log("Creating tag:", formData.title);

      formData.id = uuidv4();

      // save to MST
      self.tags.put(formData);

      // save to DB
      const model = self.tags.get(formData.id);
      model.save();

      return formData.id;
    };

    const hydrate = (data) => {
      self.tags.put(data);
    };

    const update = (formData) => {
      // save to MST
      self.tags.put(formData);

      // save to DB
      const model = self.tags.get(formData.id);
      model.save();
    };

    const remove = (model) => {
      const goalsList = getParent(self).goalList;
      const projectList = getParent(self).projectList;
      const choresList = getParent(self).choresList;

      goalsList.removeTag(model);
      projectList.removeTag(model);
      choresList.removeTag(model);

      destroy(model);
    };

    return { create, hydrate, update, remove };
  });
